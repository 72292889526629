import LoadingSpinner from "../../component/lottie/LoadingSpinner";
import "./app-loading.scss";

export const AppLoading = () => {
  return (
    <div className="app-loading">
      <LoadingSpinner width="100%" height="100%" />
    </div>
  );
};
