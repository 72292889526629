import { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch, StatefulEntity } from ".";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Returns whether a stateful entity was fetched since the hook was mounted.
 * @param storeState the stateful entity from the store to check.
 * @returns if data was fetched since mount.
 */
export const useFetchedSinceMount = (storeState: StatefulEntity<any>) => {
  // Starts at null to differentiate between before and after fetch begins (but before completion).
  const [fetched, setFetched] = useState<boolean>(null);
  // Uses change in loading state for now. Once all endpoints are updated to populate the fulfilled field,
  // could use that and compare the time instead.
  useEffect(() => {
    if (storeState.loading && !fetched) setFetched(false);
    if (!storeState.loading && fetched === false) setFetched(true);
  }, [storeState.loading]);

  // Return false when null or false.
  return fetched === true;
};
