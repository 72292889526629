/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Category } from '../models/Category';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CatalogItemCategoryService {

    /**
     * Gets categories for a business account
     * @param id ID of the requesting business account
     * @param search String to search for
     * @param limit number of results to return, sorted by description
     * @param skip number of results to skip, used for pagination. Ignored if number is not passed.
     * @returns Category OK
     * @throws ApiError
     */
    public static getCategories(
        id: string,
        search: string,
        limit?: number,
        skip?: number,
    ): CancelablePromise<Array<Category>> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/category`,
            query: {
                'search': search,
                'limit': limit,
                'skip': skip,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Adds a category
     * @param data Category
     * @param id ID of the requesting business account
     * @returns Category Created
     * @throws ApiError
     */
    public static addCategory(
        data: Category,
        id: string,
    ): CancelablePromise<Category> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/category`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets a category
     * @param id ID of the requesting business account
     * @param categoryid ID of the category to get
     * @returns Category OK
     * @throws ApiError
     */
    public static getCategory(
        id: string,
        categoryid: string,
    ): CancelablePromise<Category> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/category/${categoryid}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates a category
     * @param data Update an existing category
     * @param id ID of the requesting business account
     * @param categoryid ID of the category to be updated
     * @returns Category OK
     * @throws ApiError
     */
    public static updateCategory(
        data: Category,
        id: string,
        categoryid: string,
    ): CancelablePromise<Category> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/category/${categoryid}`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes a category
     * @param id ID of the requesting business account
     * @param categoryid ID of the category to be deleted
     * @returns Category OK
     * @throws ApiError
     */
    public static deleteCategory(
        id: string,
        categoryid: string,
    ): CancelablePromise<Category> {
        return __request({
            method: 'DELETE',
            path: `/v1/business-account/${id}/category/${categoryid}`,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}