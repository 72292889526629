import { HTMLAttributes } from "react";

export const ErrorDetail = ({
  error,
  componentStack,
  className,
  ...props
}: { error: Error; componentStack: string } & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`error-detail ${className}`} {...props}>
      <h5>Message: {error.message}</h5>
      <div>Name: {error.name}</div>
      <div>
        Stack trace: <pre className="font-size-body-condensed">{error.stack}</pre>
      </div>
      <div>
        Component stack: <pre className="font-size-body-condensed">{componentStack}</pre>
      </div>
    </div>
  );
};
