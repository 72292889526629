import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  DocumentUpdateRequest,
  Project,
  ProjectDocument,
  ProjectsResponse,
  ProjectService,
  DeleteResponse,
  DocumentRequest,
  DocumentRequestCreate,
  DocumentRequestService,
  DocumentRequestComplete,
} from "../../generated/openapi";
import { logRejectedThunk } from "../../sentry";
import { RootState, StatefulEntity } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import {
  Writable,
  createInitialState,
  error,
  loadingCondition,
  pending,
  remove,
} from "../../store/reducer";
import { resetStore } from "../../store/slice";

export const PROJECT_NAME_DUPE_ERROR = "a project with this name already exists";

/**
 * Check if type is project ID (string) or Project.
 * @param project Project object or ID.
 * @returns if given value is a Project instance.
 */
export const isProject = (project: string | Project): project is Project =>
  Boolean((project as Project)?.id);

export interface GetProjectsOptions {
  limit?: number;
  page?: number;
  sort?: string;
  direction?: string;
  status?: string;
}
export const getProjects = createAsyncThunk<
  ProjectsResponse,
  { id: string; options?: GetProjectsOptions }
>(
  "projects/get",
  async (args, thunk) =>
    returnWithErrorWrap(
      () =>
        ProjectService.getProjects(
          args.id,
          args.options?.limit,
          args.options?.page,
          args.options?.sort,
          args.options?.direction,
          args.options?.status
        ),
      thunk
    ),
  {
    condition: (arg, { getState }) => loadingCondition((getState() as RootState).project.projects),
  }
);
export const getProject = createAsyncThunk<Project, { accountId: string; projectId: string }>(
  "project/get",
  async (args) => ProjectService.getProject(args.accountId, args.projectId)
);
export const createProject = createAsyncThunk<Project, { accountId: string; data: Project }>(
  "project/create",
  async (args, thunk) =>
    returnWithErrorWrap(() => ProjectService.postProject(args.data, args.accountId), thunk)
);
export const updateProject = createAsyncThunk<
  Project,
  { accountId: string; data: Project; projectId: string }
>("project/update", async (args, thunk) =>
  returnWithErrorWrap(
    () => ProjectService.putProject(args.data, args.accountId, args.projectId),
    thunk
  )
);
export const deleteProject = createAsyncThunk<
  DeleteResponse,
  { accountId: string; projectId: string }
>("project/delete", async (args, thunk) =>
  returnWithErrorWrap(() => ProjectService.deleteProjectV2(args.accountId, args.projectId), thunk)
);

export const createProjectDocument = createAsyncThunk<
  ProjectDocument,
  { accountId: string; projectId: string; file: File }
>("project/document/create", async (args, thunk) =>
  returnWithErrorWrap(
    () => ProjectService.postProjectDocument(args.accountId, args.projectId, args.file),
    thunk
  )
);
export const deleteProjectDocument = createAsyncThunk<
  DeleteResponse,
  { accountId: string; projectId: string; documentId: string }
>("project/document/delete", async (args, thunk) =>
  returnWithErrorWrap(
    () => ProjectService.deleteProjectDocumentV2(args.accountId, args.projectId, args.documentId),
    thunk
  )
);
export const updateProjectDocument = createAsyncThunk<
  ProjectDocument,
  { accountId: string; projectId: string; documentId: string; document: DocumentUpdateRequest }
>("project/document/update", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      ProjectService.putProjectDocument(
        args.accountId,
        args.projectId,
        args.documentId,
        args.document
      ),
    thunk
  )
);
export const createProjectDocumentRequest = createAsyncThunk<
  DocumentRequest,
  { businessAccountId: string; data: DocumentRequestCreate }
>("document/request/create", async (args, thunk) =>
  returnWithErrorWrap(
    () => DocumentRequestService.postDocumentRequest(args.businessAccountId, args.data),
    thunk
  )
);

export const getProjectDocumentRequests = createAsyncThunk<
  DocumentRequest[],
  { businessAccountId: string; projectId: string }
>("document/request/getAll", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentRequestService.getDocumentRequestsByProject(args.businessAccountId, args.projectId),
    thunk
  )
);

export const deleteProjectDocumentRequest = createAsyncThunk<
  DocumentRequest,
  { businessAccountId: string; documentRequestId: string }
>("document/request/delete", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentRequestService.deleteDocumentRequest(args.businessAccountId, args.documentRequestId),
    thunk
  )
);

export const markProjectDocumentRequestAsCompleted = createAsyncThunk<
  DocumentRequest,
  { businessAccountId: string; documentRequestId: string; data: DocumentRequestComplete }
>("document/request/markAsCompleted", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentRequestService.completeDocumentRequest(
        args.businessAccountId,
        args.documentRequestId,
        args.data
      ),
    thunk
  )
);

export const resendDocumentRequest = createAsyncThunk<
  DocumentRequest,
  { businessAccountId: string; documentRequestId: string }
>("document/request/resend", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentRequestService.resendDocumentRequest(args.businessAccountId, args.documentRequestId),
    thunk
  )
);

export const selectProjectsState = (state: RootState): StatefulEntity<ProjectsResponse> =>
  state.project.projects;

const initialState = {
  projects: createInitialState<ProjectsResponse>({ additional_pages: false }),
};
const projectSlice = createSlice({
  name: "project",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects.data = action.payload;

      if (!action.payload.projects) {
        state.projects.data.projects = [];
      }
      state.projects.empty = !action.payload.projects || action.payload.projects.length === 0;
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(getProjects.rejected, (state, action) => {
      state.projects.error = action.payload;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(getProject.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      if (!state.projects.data.projects) state.projects.data.projects = [];
      const index = state.projects.data.projects.findIndex(
        (project) => project.id === action.payload.id
      );
      if (index > -1) {
        state.projects.data.projects.splice(index, 1, action.payload);
      } else {
        state.projects.data.projects.unshift(action.payload);
      }
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(getProject.rejected, (state, action) => {
      state.projects.error = action.error;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(createProject.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      if (!state.projects.data.projects) state.projects.data.projects = [];
      state.projects.data.projects.unshift(action.payload);
      state.projects.empty = state.projects.data.projects.length === 0;
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.projects.error = action.payload;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateProject.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      if (!state.projects.data.projects) state.projects.data.projects = [];
      const index = state.projects.data.projects.findIndex(
        (project) => project.id === action.payload.id
      );
      if (index > -1) {
        state.projects.data.projects.splice(index, 1, action.payload);
      } else {
        state.projects.data.projects.unshift(action.payload);
      }
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.projects.error = action.payload;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(deleteProject.pending, (state) => pending(state.projects));

    builder.addCase(deleteProject.fulfilled, (state, action) =>
      remove(state.projects, action, (st) => st.data.projects as Writable[])
    );

    builder.addCase(deleteProject.rejected, (state, action) => error(state.projects, action));

    builder.addCase(createProjectDocument.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(createProjectDocument.fulfilled, (state, action) => {
      if (!state.projects.data.projects) state.projects.data.projects = [];
      const projectIndex = state.projects.data.projects.findIndex(
        (project) => project.id === action.meta.arg.projectId
      );
      if (projectIndex > -1) {
        if (!state.projects.data.projects[projectIndex].documents)
          state.projects.data.projects[projectIndex].documents = [];
        state.projects.data.projects[projectIndex].documents.unshift(action.payload);
      }
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(createProjectDocument.rejected, (state, action) => {
      state.projects.error = action.payload;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateProjectDocument.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(updateProjectDocument.fulfilled, (state, action) => {
      if (!state.projects.data.projects) state.projects.data.projects = [];
      const projectIndex = state.projects.data.projects.findIndex(
        (project) => project.id === action.meta.arg.projectId
      );
      if (projectIndex > -1) {
        const documentIndex = state.projects.data.projects[projectIndex]?.documents?.findIndex(
          (document) => document.id === action.meta.arg.documentId
        );
        if (documentIndex > -1) {
          state.projects.data.projects[projectIndex]?.documents?.splice(
            documentIndex,
            1,
            action.payload
          );
        } else {
          state.projects.data.projects[projectIndex]?.documents?.unshift(action.payload);
        }
      }
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(updateProjectDocument.rejected, (state, action) => {
      state.projects.error = action.payload;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(deleteProjectDocument.pending, (state) => {
      state.projects.error = null;
      state.projects.loading = true;
    });
    builder.addCase(deleteProjectDocument.fulfilled, (state, action) => {
      const projectIndex = state.projects.data?.projects?.findIndex(
        (project) => project.id === action.meta.arg.projectId
      );
      if (projectIndex > -1) {
        const documentIndex = state.projects.data.projects[projectIndex]?.documents?.findIndex(
          (document) => document.id === action.meta.arg.documentId
        );
        state.projects.data.projects[projectIndex]?.documents.splice(documentIndex, 1);
      }
      state.projects.empty = state.projects.data.projects.length === 0;
      state.projects.loading = false;
      state.projects.fulfilled = Date.now();
    });
    builder.addCase(deleteProjectDocument.rejected, (state, action) => {
      state.projects.error = action.payload;
      state.projects.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default projectSlice.reducer;
