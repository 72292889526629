import { LocalizationProviderProps } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

export const dateInputLocaleProps: LocalizationProviderProps<any, any> = {
  dateAdapter: AdapterLuxon,
  localeText: {
    fieldDayPlaceholder: () => "dd",
    fieldMonthPlaceholder: (params) => (params.contentType === "digit" ? "mm" : params.format),
    fieldYearPlaceholder: (params) => (params.digitAmount === 4 ? "yyyy" : "yy"),
  },
};
