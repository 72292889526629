import { DateTime } from "luxon";
import { FleetCard, Payment } from "../../generated/openapi";
import { SuspensionStatus } from "./type";
import { TransactionStatusCategory } from "./type";
import { TranslationFunction } from "flume/i18n/hook";
export const fleetCardToUI = (card: FleetCard) => ({
  ...card,
  profile: { ...card.profile, spend_per_day: card.profile?.spend_per_day / 100 },
});

export const fleetCardToAPI = (card: FleetCard) => card;

export const maskCreditCardNumber = (ccn: string, maskType: string = "*") => {
  return ccn
    ? `${maskType.repeat(3)}${ccn.substring(Math.max(ccn.length - 5, 0), ccn.length)}`
    : "";
};

/**
 * Cleans a business name for embossing. Limits to 20 characters and removes special characters.
 * @param name Business name to clean
 * @returns the cleaned business name.
 */
export const cleanCompanyName = (name: string) => {
  return name.replace(/[^0-9a-zA-Z\s]/g, "").slice(0, 20);
};

export const getFleetSuspensionStatus = (suspendedAt: string | null): SuspensionStatus => {
  const suspensionDateUTC = DateTime.fromISO(suspendedAt);
  const currentDateUTC = DateTime.now();
  const daysSinceSuspension = currentDateUTC.diff(suspensionDateUTC, "days").days;

  if (daysSinceSuspension >= 120) {
    return SuspensionStatus.PAST_DUE_STRIKE_3;
  } else if (daysSinceSuspension >= 90) {
    return SuspensionStatus.PAST_DUE_STRIKE_2;
  } else if (daysSinceSuspension >= 10) {
    return SuspensionStatus.PAST_DUE_STRIKE_1;
  } else {
    return SuspensionStatus.NONE;
  }
};

export const mapStatusToCategory = (status: string): TransactionStatusCategory => {
  switch (status.toLowerCase()) {
    case "complete":
    case "paid":
    case "settled":
      return TransactionStatusCategory.COMPLETED;
    case "issued":
    case "in_progress":
    case "pending":
    case "approved":
      return TransactionStatusCategory.PENDING;
    case "error":
    case "canceled":
    case "rejected":
    case "refused":
    case "void":
    case "failed":
    case "returned":
    case "declined":
      return TransactionStatusCategory.DECLINED;
    default:
      return TransactionStatusCategory.UNKNOWN;
  }
};

export const getTransactionStatusText = (payment: Payment, t: TranslationFunction) => {
  const status = payment.status;
  const category = mapStatusToCategory(status);

  switch (category) {
    case TransactionStatusCategory.COMPLETED:
      return t("status.completed");
    case TransactionStatusCategory.PENDING:
      return t("status.pending");
    case TransactionStatusCategory.DECLINED:
      return t("status.declined");
    default:
      return status.charAt(0).toUpperCase() + status.slice(1);
  }
};

export const getChipColor = (category: TransactionStatusCategory) => {
  switch (category) {
    case TransactionStatusCategory.COMPLETED:
      return "success";
    case TransactionStatusCategory.PENDING:
      return "warning";
    case TransactionStatusCategory.DECLINED:
      return "error";
    default:
      return "default";
  }
};

// If it's a pre-auth and declined, return a dash, otherwise return formatted currency string
