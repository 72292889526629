/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type InvoicePaymentHistory = {
    amount?: number;
    date?: string;
    due_date?: string;
    id?: string;
    last_updated_at?: string;
    manual?: boolean;
    paid_via_flume?: boolean;
    payment_id?: string;
    payment_method?: InvoicePaymentHistory.payment_method;
    payment_type?: string;
    pdf_version?: string;
    status?: string;
}

export namespace InvoicePaymentHistory {

    export enum payment_method {
        CHECK_PAPER = 'check_paper',
        CHECK_DIGITAL = 'check_digital',
        ACH = 'ach',
        FLUME = 'flume',
        CARD = 'card',
        PAY_PORTAL_ACH = 'pay_portal_ach',
    }


}
