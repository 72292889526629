import { Components } from "@mui/material/styles";
import colors from "../theme/colors.module.scss";

declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    brand: true;
    info: true;
    neutral: true;
  }
}

export const linearProgressTheme: Components = {
  MuiLinearProgress: {
    defaultProps: {
      variant: "determinate",
    },
    styleOverrides: {
      root: {
        borderRadius: "4px",
        marginBottom: 0,
        height: "8px",
      },
      colorPrimary: {
        backgroundColor: colors["neutral-15"],
      },
      colorSecondary: {
        backgroundColor: colors["neutral-25"],
      },
      colorInfo: {
        backgroundColor: colors["neutral-25"],
      },
      colorBrand: {
        backgroundColor: colors["neutral-15"],
      },
      barColorPrimary: {
        backgroundColor: colors["wex-teal"],
      },
      barColorSecondary: {
        backgroundColor: colors["neutral-70"],
      },
      barColorInfo: {
        backgroundColor: colors["info-50"],
      },
      barColorBrand: {
        backgroundColor: colors["wex-red"],
      },
    },
  },
};
