/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentResponse } from '../models/PaymentResponse';
import type { SimplePaymentRequest } from '../models/SimplePaymentRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class InvoicePaymentService {

    /**
     * Create a simple payment request for an existing invoice
     * @param data Simple payment request for an existing invoice
     * @param id ID of the requesting business account
     * @param iid ID of the invoice
     * @returns PaymentResponse OK
     * @throws ApiError
     */
    public static postSimplePayRequestOnInvoice(
        data: SimplePaymentRequest,
        id: string,
        iid: string,
    ): CancelablePromise<PaymentResponse> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/invoice/${iid}/pay`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}