/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FleetCardStatusUpdateRequest = {
    action: FleetCardStatusUpdateRequest.action;
    reissue_reason?: FleetCardStatusUpdateRequest.reissue_reason;
}

export namespace FleetCardStatusUpdateRequest {

    export enum action {
        LOCK = 'lock',
        UNLOCK = 'unlock',
        CANCEL = 'cancel',
        REISSUE = 'reissue',
    }

    export enum reissue_reason {
        LOST = 'lost',
        STOLEN = 'stolen',
        DAMAGED = 'damaged',
    }


}
