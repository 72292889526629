import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { PublicReducerType } from "../publicSlice";
import {
  DocumentRequest,
  DocumentRequestComplete,
  DocumentRequestService,
} from "../../../generated/openapi";
import { logRejectedThunk } from "../../../sentry";
import { RootState } from "../../../store";
import { returnWithErrorWrap } from "../../../store/error";
import { error, fulfilled, pending } from "../../../store/reducer";

export const getUploadRequest = createAsyncThunk<
  DocumentRequest,
  { businessAccountId: string; requestId: string }
>("public/file/get", async (args) =>
  DocumentRequestService.getDocumentRequestWithBusinessAccount(
    args.businessAccountId,
    args.requestId
  )
);
export const uploadFile = createAsyncThunk<
  DocumentRequest,
  { businessAccountId: string; data: DocumentRequestComplete; requestId: string }
>("public/file/post", async (args, thunk) =>
  returnWithErrorWrap(
    () =>
      DocumentRequestService.completeDocumentRequestPublic(
        args.businessAccountId,
        args.requestId,
        args.data
      ),
    thunk
  )
);

export const selectUploadRequestState = (state: RootState) => state.public.fileUploadRequest;

export const buildPublicFileReducers = (builder: ActionReducerMapBuilder<PublicReducerType>) => {
  builder.addCase(getUploadRequest.pending, (state) => pending(state.fileUploadRequest));
  builder.addCase(getUploadRequest.fulfilled, (state, action) =>
    fulfilled(state.fileUploadRequest, action)
  );
  builder.addCase(getUploadRequest.rejected, (state, action) => {
    // Doesn't use error method since the payload is slightly different
    state.fileUploadRequest.error = action.error;
    state.fileUploadRequest.loading = false;
    logRejectedThunk(state, action);
  });

  builder.addCase(uploadFile.pending, (state) => pending(state.fileUploadRequest));
  builder.addCase(uploadFile.fulfilled, (state, action) =>
    fulfilled(state.fileUploadRequest, action)
  );
  builder.addCase(uploadFile.rejected, (state, action) => error(state.fileUploadRequest, action));
};
