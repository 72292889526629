/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from '../models/DeleteResponse';
import type { Document } from '../models/Document';
import type { DocumentUpdateRequest } from '../models/DocumentUpdateRequest';
import type { ZipDocument } from '../models/ZipDocument';
import type { ZipRequest } from '../models/ZipRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * Creates a document
     * @param id ID the business account this document belongs to
     * @param file Document to create
     * @returns Document OK
     * @throws ApiError
     */
    public static postDocument(
        id: string,
        file: Blob,
    ): CancelablePromise<Document> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/document`,
            formData: {
                'file': file,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates an existing document.
     * @param data Document update data
     * @param id ID of the business for which the document will be updated
     * @param docid ID of the document to update
     * @returns DocumentUpdateRequest OK
     * @throws ApiError
     */
    public static privatePutDocument(
        data: Document,
        id: string,
        docid: string,
    ): CancelablePromise<DocumentUpdateRequest> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/document/${docid}`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets a specific set of documents and returns a zip file url.
     * @param id ID of the business for which the documents are being requested
     * @param data list of files to get
     * @returns ZipDocument OK
     * @throws ApiError
     */
    public static zipDocuments(
        id: string,
        data: ZipRequest,
    ): CancelablePromise<ZipDocument> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/documents`,
            body: data,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Gets a specific document.
     * @param id ID of the document to fetch
     * @returns string OK
     * @throws ApiError
     */
    public static getDocument(
        id: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/v1/document/${id}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Creates a public document
     * @param id FlumeTag or ID the business account this document belongs to
     * @param file Document to create
     * @returns Document OK
     * @throws ApiError
     */
    public static publicPostDocument(
        id: string,
        file: Blob,
    ): CancelablePromise<Document> {
        return __request({
            method: 'POST',
            path: `/v1/public/${id}/document`,
            formData: {
                'file': file,
            },
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Removes a document
     * @param id ID of the business account that owns the document to be deleted
     * @param docid ID of the document to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteDocumentV2(
        id: string,
        docid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request({
            method: 'DELETE',
            path: `/v2/business-account/${id}/document/${docid}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

}