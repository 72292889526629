import { Paper } from "@mui/material";
import { useI18n } from "flume/i18n";
import { StorageLifetime, useStorage } from "flume/shared/storage";
import { useCallback, useEffect } from "react";
import { Button } from "../button";
import { TimedLinearProgress } from "../progressbar/TimedLinearProgress";
import { ErrorBoundaryFallbackProps } from "./ErrorBoundaryFallbackProps";

export const NewBuildError = (props: ErrorBoundaryFallbackProps) => {
  const t = useI18n({ namespaces: ["errors"], prefix: "newBuild" });
  const [factor, setFactor] = useStorage("error_retry_factor", 50, StorageLifetime.SESSION);
  const step = 2000 / factor;
  const delay = 200;

  /**
   * Handles the refresh action by doubling the factor state if it is less than 5000,
   * and then reloads the current window.
   *
   * @function
   * @name handleRefresh
   * @returns {void}
   */
  const handleRefresh = useCallback(() => {
    setFactor((s) => (s < 5000 ? s * 2 : s));
    window.location.reload();
  }, [setFactor]);

  useEffect(() => {
    //Forces a hot refresh the first two renders before starting to show the user the progress bar etc.
    if (factor <= 100) {
      handleRefresh();
    }
  }, [factor, handleRefresh]);

  return (
    <Paper className="container flex-grow-1 my-2 d-flex justify-content-center align-items-stretch flex-column error-fallback">
      <div className="d-flex justify-content-center align-items-center flex-column p-2 align-self-center error-fallback-text">
        <img src="/img/search-empty.svg" alt="" />
        <div>
          <h2>{t("title")}</h2>
          <p className="text-muted font-size-body-condensed p-0">{t("description")}</p>
        </div>
        <Button onClick={handleRefresh} className="mt-2" fullWidth>
          {t("cta")}
        </Button>
        <div className="w-100">
          <TimedLinearProgress
            start={0}
            step={step}
            delay={delay}
            color="primary"
            variant="determinate"
            className="mt-2"
            onFinish={handleRefresh}
          />
        </div>
      </div>
    </Paper>
  );
};
