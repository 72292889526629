import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { Components } from "@mui/material/styles";
import { FlumeIcon } from "../icon";
import colors from "../theme/colors.module.scss";

export const alertTheme: Components = {
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        info: <FlumeIcon icon={faCircleInfo} />,
        warning: <FlumeIcon icon={faTriangleExclamation} />,
      },
    },
    styleOverrides: {
      root: {
        color: colors["neutral-100"],
        padding: "8px 10px",
      },
      icon: {
        alignItems: "center",
        fontSize: "16px",
      },
      message: {
        padding: 0,
        fontSize: "14px",
        lineHeight: "24px",
      },
      filled: {
        color: colors["neutral-00"],
      },

      standardInfo: {
        backgroundColor: colors["info-10"],
        ".MuiAlert-icon": { color: colors["info-60"] },
      },
      filledInfo: {
        backgroundColor: colors["info-50"],
      },
      outlinedInfo: {
        borderColor: colors["info-50"],
        ".MuiAlert-icon": { color: colors["info-60"] },
      },

      standardSuccess: {
        backgroundColor: colors["success-10"],
        ".MuiAlert-icon": { color: colors["success-50"] },
      },
      filledSuccess: {
        backgroundColor: colors["success-40"],
      },
      outlinedSuccess: {
        borderColor: colors["success-40"],
        ".MuiAlert-icon": { color: colors["success-50"] },
      },

      standardWarning: {
        backgroundColor: colors["warning-10"],
        ".MuiAlert-icon": { color: colors["warning-50"] },
      },
      filledWarning: {
        backgroundColor: colors["warning-40"],
      },
      outlinedWarning: {
        borderColor: colors["warning-40"],
        ".MuiAlert-icon": { color: colors["warning-50"] },
      },

      standardError: {
        backgroundColor: colors["critical-10"],
        ".MuiAlert-icon": { color: colors["critical-50"] },
      },
      filledError: {
        backgroundColor: colors["critical-50"],
      },
      outlinedError: {
        borderColor: colors["critical-40"],
        ".MuiAlert-icon": { color: colors["critical-50"] },
      },
    },
  },
};
