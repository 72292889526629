/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentVerificationRequest } from '../models/DocumentVerificationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AccountVerificationService {

    /**
     * Gets a specific document verification request.
     * @param dvrid ID of the document verification request to get
     * @param id ID of the business requesting
     * @returns DocumentVerificationRequest OK
     * @throws ApiError
     */
    public static getDocumentVerificationRequest(
        dvrid: string,
        id: string,
    ): CancelablePromise<DocumentVerificationRequest> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/account-verification/document-verification-request/${dvrid}`,
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * Uploads a document for a document request
     * @param id ID of the business uploading the document
     * @param dvrid document verification request id to complete
     * @param file Document to create
     * @param fileType Type of file being submitted
     * @returns DocumentVerificationRequest OK
     * @throws ApiError
     */
    public static postVerificationDocument(
        id: string,
        dvrid: string,
        file: Blob,
        fileType: string,
    ): CancelablePromise<DocumentVerificationRequest> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/account-verification/document-verification-request/${dvrid}`,
            formData: {
                'file': file,
                'file_type': fileType,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets all document verification requests for a business.
     * @param id ID of the business to get the document request for
     * @returns DocumentVerificationRequest OK
     * @throws ApiError
     */
    public static getAllDocumentVerificationRequests(
        id: string,
    ): CancelablePromise<Array<DocumentVerificationRequest>> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/account-verification/document-verification-requests`,
            errors: {
                400: `Bad Request`,
            },
        });
    }

}