/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessConfiguration } from '../models/BusinessConfiguration';
import type { ConfigurationValue } from '../models/ConfigurationValue';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ConfigurationService {

    /**
     * Set the specified business configuration item
     * @param data Value to set
     * @param id ID of the business account
     * @returns BusinessConfiguration OK
     * @throws ApiError
     */
    public static putBusinessConfiguration(
        data: BusinessConfiguration,
        id: string,
    ): CancelablePromise<BusinessConfiguration> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/configuration-item`,
            body: data,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates the business logo to the image specified by file
     * @param id ID of the business account
     * @param file Logo image file
     * @returns BusinessConfiguration OK
     * @throws ApiError
     */
    public static postBusinessLogo(
        id: string,
        file: Blob,
    ): CancelablePromise<BusinessConfiguration> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/configuration-item/LOGO`,
            formData: {
                'file': file,
            },
            errors: {
                404: `Not Found`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete the specified business configuration item
     * @param id ID of the business account
     * @param key configuration key
     * @returns void
     * @throws ApiError
     */
    public static deleteBusinessConfigurationItem(
        id: string,
        key: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/v1/business-account/${id}/configuration-item/${key}`,
            errors: {
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get a value for a specified key from the configuration-service
     * @param key configuration item key
     * @returns ConfigurationValue OK
     * @throws ApiError
     */
    public static getConfigurationItem(
        key: string,
    ): CancelablePromise<ConfigurationValue> {
        return __request({
            method: 'GET',
            path: `/v1/configuration-items/${key}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Get the specified business configuration item
     * @param id ID of the business account
     * @param key configuration key
     * @returns BusinessConfiguration OK
     * @throws ApiError
     */
    public static getBusinessConfigurationItem(
        id: string,
        key: string,
    ): CancelablePromise<BusinessConfiguration> {
        return __request({
            method: 'GET',
            path: `/v1/public/business-account/${id}/configuration-item/${key}`,
            errors: {
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get value of the specified business configuration in the native content type
     * @param id ID of the business account
     * @param key configuration key
     * @returns string OK
     * @throws ApiError
     */
    public static getBusinessConfigurationItemValue(
        id: string,
        key: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/v1/public/business-account/${id}/configuration-item/${key}/value`,
            errors: {
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}