import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { PublicReducerType } from "../publicSlice";
import {
  EstimateService,
  Invoice,
  InvoiceService,
  InvoiceUpdateRequest,
} from "../../../generated/openapi";
import { logRejectedThunk } from "../../../sentry";
import { RootState } from "../../../store";
import { clear, fulfilled, pending } from "../../../store/reducer";
import { WritableDraft } from "immer/dist/internal";

export const getEstimate = createAsyncThunk<
  Invoice,
  { businessAccountId: string; estimateId: string }
>("public/estimate/get", async (args) =>
  InvoiceService.publicGetEstimate(args.businessAccountId, args.estimateId)
);
export const updateEstimate = createAsyncThunk<
  Invoice,
  { businessAccountId: string; data: InvoiceUpdateRequest; estimateId: string }
>("public/estimate/put", async (args) =>
  EstimateService.putEstimateApprove(args.data, args.businessAccountId, args.estimateId)
);
export const clearInvoice = createAsyncThunk("public/invoice/clear", async () => {});

export const selectInvoiceState = (state: RootState) => state.public.invoice;

// Handles error without a triggering the generic error notification.
const handleError = (state: WritableDraft<PublicReducerType>, action: any) => {
  state.invoice.error = action.error;
  state.invoice.loading = false;
  logRejectedThunk(state.invoice, action);
};

export const buildPublicInvoiceReducers = (builder: ActionReducerMapBuilder<PublicReducerType>) => {
  builder.addCase(getEstimate.pending, (state) => pending(state.invoice));
  builder.addCase(getEstimate.fulfilled, (state, action) => fulfilled(state.invoice, action));
  builder.addCase(getEstimate.rejected, handleError);

  builder.addCase(updateEstimate.pending, (state) => pending(state.invoice));
  builder.addCase(updateEstimate.fulfilled, (state, action) => fulfilled(state.invoice, action));
  builder.addCase(updateEstimate.rejected, handleError);

  builder.addCase(clearInvoice.pending, (state) => clear(state.invoice));
};
