import {
  Invoice,
  InvoicePaymentHistory,
  InvoiceUpdateRequest,
  Payment,
  UpdatePaymentRequest,
  Project,
  DeleteResponse,
} from "../../../generated/openapi";
import { RequestPaymentFormState } from "../receive";
import { EventCallback } from "../../../shared/context/dispatchEvent";

export type InvoiceType = "invoice" | "estimate";

export enum InvoiceAction {
  VIEW = "invoice.view",
  CREATE = "invoice.create",
  DUPLICATE = "invoice.duplicate",
  REQUEST_PAYMENT = "invoice.request.payment",
  MANUAL_PAYMENT = "invoice.request.manual",
  DELETE = "invoice.delete",
  EDIT = "invoice.edit",
  UPDATE_STATUS = "invoice.update.status",
  ADD_PROJECT = "invoice.add.project",
  REMOVE_PROJECT = "invoice.remove.project",
  SHARE = "invoice.share",
}

export enum InvoicePaymentAction {
  CANCEL = "invoice.payment.cancel",
  DELETE = "invoice.payment.delete",
  EDIT = "invoice.payment.edit",
  MARK_DELETE = "invoice.payment.mark-delete", // Mark for deletion.
  MARK_PAID = "invoice.payment.mark-paid",
  MARK_REFUSED = "invoice.payment.mark-refused",
  RESEND = "invoice.payment.resend",
  UPDATE = "invoice.payment.update",
  VIEW = "invoice.payment.view",
  SHARE = "invoice.payment.share",
}

export enum EstimateAction {
  SEND = "estimate.send",
  CONVERT = "estimate.convert",
  RESEND = "estimate.resend",
}

export type InvoiceEventPayload = {
  invoices?: Invoice[];
  invoice?: Invoice;
  status?: InvoiceUpdateRequest.status | InvoiceUpdateRequest.estimate_status;
  statusToUndo?: InvoiceUpdateRequest.status | InvoiceUpdateRequest.estimate_status;
  onSave?: (formState: RequestPaymentFormState) => void;
  project?: Project;
};

export interface InvoicePaymentEventPayload extends EventCallback<Payment> {
  invoice?: Invoice;
  payment?: InvoicePaymentHistory;
}

export interface InvoicePaymentDeletePayload extends EventCallback<DeleteResponse> {
  invoiceId: string;
  paymentId: string;
}

export interface InvoicePaymentUpdatePayload extends EventCallback<Payment> {
  data: UpdatePaymentRequest;
  paymentId: string;
}
