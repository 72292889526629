import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { ButtonProps as MuiButtonProps } from "@mui/material";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { FlumeIcon } from "../icon";
import createStyledButton from "./styles";

export enum ButtonSize {
  LARGE = "large",
  MEDIUM = "medium",
  SMALL = "small",
  XSMALL = "xsmall",
}
export type ButtonSizeValue = "lg" | "md" | "sm" | "xs";
/**
 * Gets the ButtonSize from the enum or string value. Maps it to the enum value if needed.
 * @param size the size passed as a prop.
 * @returns the ButtonSize.
 */
export const getButtonSize = (size: ButtonSize | ButtonSizeValue) => {
  switch (size) {
    case "lg":
      return ButtonSize.LARGE;
    case "md":
      return ButtonSize.MEDIUM;
    case "sm":
      return ButtonSize.SMALL;
    case "xs":
      return ButtonSize.XSMALL;
    default:
      return size;
  }
};

export enum ButtonTheme {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  WARNING = "warning",
  CRITICAL = "error",
  NEUTRAL = "info",
  SUCCESS = "success",
  BRAND = "brand",
}
export type ButtonThemeValue =
  | "primary"
  | "secondary"
  | "tertiary"
  | "warning"
  | "critical"
  | "neutral"
  | "success"
  | "brand";
/**
 * Gets the ButtonTheme from the enum or string value. Maps it to the enum value if needed.
 * @param theme the theme passed as a prop.
 * @returns the ButtonTheme.
 */
export const getButtonTheme = (theme: ButtonTheme | ButtonThemeValue) => {
  switch (theme) {
    case "critical":
      return ButtonTheme.CRITICAL;
    case "neutral":
      return ButtonTheme.NEUTRAL;
    default:
      return theme as ButtonTheme;
  }
};

export enum ButtonVariant {
  PRIMARY = "contained",
  SECONDARY = "outlined",
  TERTIARY = "text",
}
export type ButtonVariantValue = "primary" | "secondary" | "tertiary";
/**
 * Gets the ButtonVariant from the enum or string value. Maps it to the enum value if needed.
 * @param variant the variant passed as a prop.
 * @returns the ButtonVariant.
 */
export const getButtonVariant = (variant: ButtonVariant | ButtonVariantValue) => {
  switch (variant) {
    case "primary":
      return ButtonVariant.PRIMARY;
    case "secondary":
      return ButtonVariant.SECONDARY;
    case "tertiary":
      return ButtonVariant.TERTIARY;
    default:
      return variant;
  }
};

export interface ButtonProps extends Omit<MuiButtonProps, "size" | "variant"> {
  attributes?: Object;
  disableText?: string;
  icon?: React.ReactNode;
  isIconRight?: boolean;
  loading?: boolean;
  loadingText?: string;
  rounded?: boolean;
  size?: ButtonSize | ButtonSizeValue;
  theme?: ButtonTheme | ButtonThemeValue;
  to?: string;
  type?: "button" | "submit" | "reset";
  variant?: ButtonVariant | ButtonVariantValue;
  width?: string;
}

export const Button = ({
  attributes = {},
  children,
  className = "",
  disabled = false,
  disableText = "",
  fullWidth = false,
  href = null,
  icon = null,
  isIconRight = false,
  loading = false,
  loadingText = "",
  onClick,
  rounded = false,
  size = ButtonSize.MEDIUM,
  variant = ButtonVariant.PRIMARY,
  theme = ButtonTheme.PRIMARY,
  to = "",
  type = "button",
  width = "",
  ...props
}: ButtonProps) => {
  const getIconProps = () => {
    const position = isIconRight ? "endIcon" : "startIcon";
    return {
      [position]: loading ? <FlumeIcon icon={faSpinnerThird}></FlumeIcon> : icon,
    };
  };
  const getPathProperties = () => {
    if (href) return { href };
    if (to) return { component: Link, to };
    return {};
  };
  const getChildren = () => {
    if (disabled && disableText) return disableText;
    if (loading && loadingText) return loadingText;
    return children;
  };

  const calculatedSize = useMemo(() => getButtonSize(size), [size]);
  const calculatedTheme = useMemo(() => getButtonTheme(theme), [theme]);
  const calculatedVariant = useMemo(() => getButtonVariant(variant), [variant]);

  const WexButton = useMemo(
    () =>
      createStyledButton({
        size: calculatedSize,
        theme: calculatedTheme,
        rounded: rounded,
        variant: calculatedVariant,
        width,
      }),
    [calculatedSize, calculatedTheme, calculatedVariant, rounded, width]
  );

  return (
    <WexButton
      className={`${className + (loading ? " loading" : "")}`}
      color={calculatedTheme}
      disabled={disabled || loading}
      disableFocusRipple={true}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      size={calculatedSize === ButtonSize.XSMALL ? ButtonSize.SMALL : calculatedSize}
      variant={calculatedVariant}
      type={type}
      {...getIconProps()}
      {...getPathProperties()}
      {...attributes}
      {...props}
    >
      {getChildren()}
    </WexButton>
  );
};

export default Button;
