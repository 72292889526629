import { useEffect, useMemo, useState } from "react";

export enum StorageLifetime {
  SESSION, // Persists until log out.
  REFRESH, // Persists until main app mounts (page refresh, log in, etc.).
  INFINITE, // Persists until manually removed.
}

export enum StorageKey {
  INVITE_CODE = "invite_code",
  INVITE_CODE_DATE = "invite_code_date",
  LAST_LOGIN = "last_login",
  LINK_TOKEN = "link_token",
  INVOICE_GRID_FILTERS = "filter.invoice",
  ESTIMATE_GRID_FILTERS = "filter.estimate",
  PROJECT_STATUS_FILTERS = "filter.project.status",
  ACTIVITY_AMOUNT_FILTERS = "filter.activity.amount",
  ACTIVITY_DATE_FILTERS = "filter.activity.date",
  ACTIVITY_METHOD_FILTERS = "filter.activity.method",
  ACTIVITY_TYPE_FILTERS = "filter.activity.type",
  ACTIVITY_PROJECT_FILTERS = "filter.activity.project",
  ACTIVITY_GRID_FILTERS = "filter.activity",
  PROMO_SEEN = "promo_seen",
  ACCOUNT_TYPE = "account_type",
  FUEL_STATEMENT_GRID_FILTERS = "filter.fuel.statement",
  FUEL_STATEMENT_CARD_VIEW_GRID_FILTERS = "filter.fuel.statement.card",
  FUEL_NAV_OPEN = "fuel_nav_open",
  BUSINESS_NAV_OPEN = "business_nav_open",
  PROJECTS_NAV_OPEN = "projects_nav_open",
  RESOURCES_NAV_OPEN = "resources_nav_open",
  DCA_BUSINESS_NAME = "dca.business_name",
  INITIAL_CARD_FORM = "initial_card_form",
}

/**
 * Hook to set/retrieve value from local storage and use as state.
 * @param key key to retrieve or store data at in local storage.
 * @param initial if key does not exist in local storage, saves this value to the key in local storage.
 * @param lifetime how long the data should persist.
 * @returns data and data setter for local storage.
 */
export const useStorage = <T>(
  key: string,
  initial: T,
  lifetime: StorageLifetime = StorageLifetime.INFINITE
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const prevState = useMemo(() => JSON.parse(localStorage.getItem(key))?.state, [key]);
  const data = prevState === undefined ? initial : prevState;
  const [state, setState] = useState<T>(data);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify({ lifetime, state }));
  }, [state, key, lifetime]);

  return [state, setState];
};

/**
 * Removes all data of a given lifetime from storage.
 * @param lifetime the type of lifetime to remove data of.
 */
export const purgeStorage = (lifetime: StorageLifetime) => {
  for (const [key, value] of Object.entries(localStorage)) {
    try {
      // Catch errors when data is not JSON parseable.
      JSON.parse(value)?.lifetime === lifetime && localStorage.removeItem(key);
    } catch (e) {}
  }
};

// Should deprecate in favor of useStorage.
export const Storage = {
  getInviteCode: () => localStorage.getItem(StorageKey.INVITE_CODE),
  getInviteCodeDate: () => localStorage.getItem(StorageKey.INVITE_CODE_DATE),
  getLastLogin: () => localStorage.getItem(StorageKey.LAST_LOGIN),
  getLinkToken: () => localStorage.getItem(StorageKey.LINK_TOKEN),
  getPromoSeen: () => localStorage.getItem(StorageKey.PROMO_SEEN),

  removeInviteCode: () => localStorage.removeItem(StorageKey.INVITE_CODE),
  removeInviteCodeDate: () => localStorage.removeItem(StorageKey.INVITE_CODE_DATE),
  removeLastLogin: () => localStorage.removeItem(StorageKey.LAST_LOGIN),
  removeLinkToken: () => localStorage.removeItem(StorageKey.LINK_TOKEN),
  removePromoSeen: () => localStorage.removeItem(StorageKey.PROMO_SEEN),

  setInviteCode: (value: string) => localStorage.setItem(StorageKey.INVITE_CODE, value),
  setInviteCodeDate: (value: string) => localStorage.setItem(StorageKey.INVITE_CODE_DATE, value),
  setLastLogin: (value: string) => localStorage.setItem(StorageKey.LAST_LOGIN, value),
  setLinkToken: (value: string) => localStorage.setItem(StorageKey.LINK_TOKEN, value),
  setPromoSeen: (value: string) => localStorage.setItem(StorageKey.PROMO_SEEN, value),
};
