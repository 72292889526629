import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { Plan, SubscriptionService, SubscriptionResponse } from "../../generated/openapi";
import { logRejectedThunk } from "../../sentry";
import { RootState } from "../../store";
import { createInitialState } from "../../store/reducer";
import { resetStore } from "../../store/slice";

export const clearLatestSubscriptionUpdate = createAsyncThunk("subscription/clear", async () => {});
export const getAllPlans = createAsyncThunk("plans/get", async () =>
  SubscriptionService.getPlans()
);
export const getIndividualPlan = createAsyncThunk("plan/get", async (planId: string) =>
  SubscriptionService.getPlan(planId)
);
export const getAccountSubscription = createAsyncThunk(
  "subscription/get",
  async (accountId: string) => SubscriptionService.getSubscription(accountId)
);
export const updateSubscription = createAsyncThunk(
  "subscription/update",
  async (args: { accountId: string; planId: string }) =>
    SubscriptionService.updateSubscription(args.accountId, { plan_id: args.planId })
);

export const selectPlans = (state: RootState) => state.plan.all;
export const selectPlanInfo = (state: RootState) => state.plan.info;
export const selectIndividualPlan = (planId: string) =>
  createSelector(selectPlanInfo, (plans) => plans.data[planId]);
export const selectAccountSubscription = (state: RootState) => state.plan.subscription;
export const selectSubscriptionUpdateResponse = (state: RootState) => state.plan.update;

const initialState = {
  all: createInitialState<Plan[]>([]),
  info: createInitialState<Record<string, Plan>>({}),
  subscription: createInitialState<SubscriptionResponse>(),
  update: createInitialState<SubscriptionResponse>(),
};
const planSlice = createSlice({
  name: "plan",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(clearLatestSubscriptionUpdate.fulfilled, (state) => {
      state.update.data = null;
      state.update.empty = true;
      state.update.error = null;
      state.update.loading = false;
    });

    builder.addCase(getAllPlans.pending, (state) => {
      state.all.error = null;
      state.all.loading = true;
    });
    builder.addCase(getAllPlans.fulfilled, (state, action) => {
      state.all.loading = false;
      state.all.data = action.payload;
      state.all.empty = action.payload?.length === 0;
    });
    builder.addCase(getAllPlans.rejected, (state, action) => {
      state.all.loading = false;
      state.all.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(getIndividualPlan.pending, (state) => {
      state.info.error = null;
      state.info.loading = true;
    });
    builder.addCase(getIndividualPlan.fulfilled, (state, action) => {
      state.info.loading = false;
      state.info.data[action.payload.id] = action.payload;
      state.info.empty = Boolean(action.payload);
    });
    builder.addCase(getIndividualPlan.rejected, (state, action) => {
      state.info.loading = false;
      state.info.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(getAccountSubscription.pending, (state) => {
      state.subscription.error = null;
      state.subscription.loading = true;
    });
    builder.addCase(getAccountSubscription.fulfilled, (state, action) => {
      state.subscription.loading = false;
      state.subscription.data = action.payload;
      state.subscription.empty = Boolean(action.payload);
    });
    builder.addCase(getAccountSubscription.rejected, (state, action) => {
      state.subscription.loading = false;
      state.subscription.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(updateSubscription.pending, (state) => {
      state.update.error = null;
      state.update.loading = true;
    });
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.update.loading = false;
      state.update.data = action.payload;
      state.update.empty = Boolean(action.payload);
    });
    builder.addCase(updateSubscription.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
      logRejectedThunk(state, action);
    });

    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default planSlice.reducer;
