import { useEffect } from "react";
import { Storage } from "../../shared/storage";

export const InviteCodeObserver = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("inviteCode");
    if (code) {
      Storage.setInviteCode(code);
      Storage.setInviteCodeDate(new Date().toISOString());
    }
  }, []);

  return null;
};
