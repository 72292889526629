import { Components } from "@mui/material/styles";
import colors from "../theme/colors.module.scss";

export const tooltipTheme: Components = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        borderRadius: "4px",
        padding: "4px 12px",
        backgroundColor: colors["neutral-70"],
        color: colors["neutral-00"],
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        a: {
          color: colors["primary-30"],
          textDecoration: "none",
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
      tooltipPlacementBottom: {
        marginTop: "0 !important",
      },
      tooltipPlacementTop: {
        marginBottom: "0 !important",
      },
      tooltipPlacementLeft: {
        marginRight: "0 !important",
      },
      tooltipPlacementRight: {
        marginLeft: "0 !important",
      },
    },
  },
};
