import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { buildPublicFileReducers } from "./file/fileSlice";
import { buildPublicInvoiceReducers } from "./invoice/invoiceSlice";
import { buildPublicPaymentReducers } from "./payment/paymentSlice";
import {
  BusinessAccount,
  CheckoutLink,
  DocumentRequest,
  Invoice,
  PublicInvoice,
  UserService,
} from "../../generated/openapi";
import { RootState, StatefulEntity } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import { createInitialState, error, fulfilled, pending } from "../../store/reducer";
import { resetStore } from "../../store/slice";

export type PublicReducerType = {
  business: StatefulEntity<BusinessAccount>;
  fileUploadRequest: StatefulEntity<DocumentRequest>;
  invoice: StatefulEntity<Invoice>;
  paymentInvoice: StatefulEntity<PublicInvoice>;
  paymentLink: StatefulEntity<CheckoutLink>;
};

/**
 * Validate public business account.
 * @param arg Business account id or Flumetag. Flumetag should be URL encoded.
 */
export const validateBusiness = createAsyncThunk<BusinessAccount, string>(
  "public/business/validate/get",
  async (id, thunk) => returnWithErrorWrap(() => UserService.publicValidateHandler(id), thunk)
);
export const selectRecipientState = (state: RootState) => state.public.business;

const initialState = {
  business: createInitialState<BusinessAccount>(),
  fileUploadRequest: createInitialState<DocumentRequest>(),
  invoice: createInitialState<Invoice>(),
  paymentInvoice: createInitialState<PublicInvoice>(),
  paymentLink: createInitialState<CheckoutLink>(),
};

const publicSlice = createSlice({
  name: "public",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(validateBusiness.pending, (state) => pending(state.business));
    builder.addCase(validateBusiness.fulfilled, (state, action) =>
      fulfilled(state.business, action)
    );
    builder.addCase(validateBusiness.rejected, (state, action) => error(state.business, action));

    buildPublicPaymentReducers(builder);
    buildPublicInvoiceReducers(builder);
    buildPublicFileReducers(builder);
    builder.addCase(resetStore, () => ({ ...initialState }));
  },
});

export default publicSlice.reducer;
