import { Button, ButtonProps } from "./Button";
import "./muted-button.scss";

type MutedButtonProps = Omit<ButtonProps, "theme" | "variant">;
/**
 * The button with the grey background and no border.
 */
export const MutedButton = (props: MutedButtonProps) => {
  return (
    <Button
      classes={{
        root: "muted-button",
        disabled: "muted-button-disabled",
        endIcon: "muted-button-icon",
        startIcon: "muted-button-icon",
      }}
      {...props}
    />
  );
};
