import { ApiError } from "../generated/openapi";
import { notifyError, notifyValidationError } from "../module/notification/notificationSlice";

export const errorHandler = (state, action) => {
  console.log("Error in redux action %o", action);
};

export const returnWithErrorWrap = async <T>(
  closure: () => Promise<T>,
  thunk,
  modifyErrorNotification?: (error: ApiError) => string // Only for non-422 errors.
) => {
  try {
    return await closure();
  } catch (e) {
    if (e.status === 422) {
      thunk.dispatch(notifyValidationError(e.body));
    } else {
      thunk.dispatch(
        notifyError({
          ...e.body,
          ...(modifyErrorNotification ? { error: modifyErrorNotification(e) } : {}),
        })
      );
    }
    throw thunk.rejectWithValue(e.body);
  }
};
