import i18next from "i18next";

/**
 * Changes the language used by the translation service.
 * @param language the ISO 639-1 code of the language to change to.
 * @returns promise for when language changes.
 */
export const changeI18nLanguage = (language: string) => i18next.changeLanguage(language);

/**
 * Gets the current language used by the translation service.
 * @returns Gets the ISO 639-1 code of the current language.
 */
export const getI18nLanguage = () => i18next.language;
