import React, { createElement } from "react";
import { ErrorBoundary } from "@sentry/react";

export class FlumeErrorBoundary extends ErrorBoundary {
  public render(): React.ReactNode {
    const { fallback, children } = this.props;
    const state = this.state;
    if (state.error) {
      let element: React.ReactElement | undefined = undefined;
      if (typeof fallback === "function") {
        //This is almost an identical copy to Sentry's error boundary, however they call the "fallback" as a function instead of treating it like an element.
        //This prevents the fallback component from using any hooks.
        element = createElement(fallback, {
          error: state.error,
          componentStack: state.componentStack,
          resetError: this.resetErrorBoundary,
          eventId: state.eventId,
        });
      } else {
        element = fallback;
      }

      if (React.isValidElement(element)) {
        return element;
      }

      // Fail gracefully if no fallback provided or is not valid
      return null;
    }

    if (typeof children === "function") {
      return (children as () => React.ReactNode)();
    }
    return children;
  }
}
