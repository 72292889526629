import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { StorageKey, useStorage } from "../../shared/storage";

export const StoreAccountType = () => {
  const [queryParams] = useSearchParams();
  const accountType = queryParams.get("accountType");
  const [_savedType, setSavedType] = useStorage(StorageKey.ACCOUNT_TYPE, ""); // eslint-disable-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    accountType === "mmda" && setSavedType(accountType);
  }, [accountType, setSavedType]);

  return <></>;
};
