/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Document } from './Document';
import type { Invoice } from './Invoice';
import type { PublicPayor } from './PublicPayor';

export type PublicInvoice = {
    amount: number;
    currency: string;
    documents?: Array<Document>;
    invoice?: Invoice;
    memo?: string;
    notes?: string;
    payee?: PublicPayor;
    payment_date: string;
    payment_method?: PublicInvoice.payment_method;
    payment_type?: PublicInvoice.payment_type;
    payor: PublicPayor;
}

export namespace PublicInvoice {

    export enum payment_method {
        CARD = 'card',
        ACH = 'ach',
    }

    export enum payment_type {
        PAYEE = 'payee',
    }


}
