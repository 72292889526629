/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { Fleet } from './Fleet';
import type { Prefund } from './Prefund';

export type BusinessAccountUpdateRequest = {
    account_category?: BusinessAccountUpdateRequest.account_category;
    address: Address;
    business_type?: string;
    contact_name?: string;
    email: string;
    fleet?: Fleet;
    flume_tag?: string;
    id?: string;
    individual_category?: BusinessAccountUpdateRequest.individual_category;
    legal_structure: BusinessAccountUpdateRequest.legal_structure;
    naics_code: string;
    name?: string;
    operation_description?: string;
    phone: string;
    prefund?: Prefund;
    tax_id: string;
    tax_type?: BusinessAccountUpdateRequest.tax_type;
}

export namespace BusinessAccountUpdateRequest {

    export enum account_category {
        INDIVIDUAL = 'individual',
        BUSINESS = 'business',
    }

    export enum individual_category {
        CUSTOMER = 'customer',
        EMPLOYEE = 'employee',
    }

    export enum legal_structure {
        CORPORATION = 'corporation',
        LIMITED_LIABILITY_COMPANY = 'limited_liability_company',
        LIMITED_LIABILITY_PARTNERSHIP = 'limited_liability_partnership',
        NON_PROFIT_DOMESTIC_CORPORATION = 'non_profit_domestic_corporation',
        S_CORPORATION = 's_corporation',
        SOLE_PROPRIETOR = 'sole_proprietor',
    }

    export enum tax_type {
        SSN = 'SSN',
        EIN = 'EIN',
        TIN = 'TIN',
    }


}
