/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePaymentRequest = {
    business_account_id: string;
    method?: string;
    payment_date?: string;
    send_receipt?: boolean;
    status: UpdatePaymentRequest.status;
    status_change_note?: string;
}

export namespace UpdatePaymentRequest {

    export enum status {
        PAID = 'paid',
        REFUSED = 'refused',
    }


}
