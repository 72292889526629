/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Document } from './Document';
import type { Invoice } from './Invoice';
import type { Payee } from './Payee';
import type { PaymentProject } from './PaymentProject';

export type PaymentRequest = {
    amount: number;
    currency: string;
    destination_account?: PaymentRequest.destination_account;
    documents?: Array<Document>;
    erp_bill_id?: string;
    invoice?: Invoice;
    memo?: string;
    notes?: string;
    payee: Payee;
    payment_date: string;
    payment_id?: string;
    payment_method?: PaymentRequest.payment_method;
    payment_type?: PaymentRequest.payment_type;
    projects?: Array<PaymentProject>;
    source_account?: PaymentRequest.source_account;
}

export namespace PaymentRequest {

    export enum destination_account {
        INTERNAL = 'internal',
        EXTERNAL = 'external',
    }

    export enum payment_method {
        CHECK_PAPER = 'check_paper',
        CHECK_DIGITAL = 'check_digital',
        ACH = 'ach',
    }

    export enum payment_type {
        PAYOR = 'payor',
        PAYEE = 'payee',
        FUND = 'fund',
        WITHDRAW = 'withdraw',
        AR = 'ar',
        AP = 'ap',
    }

    export enum source_account {
        INTERNAL = 'internal',
        EXTERNAL = 'external',
    }


}
