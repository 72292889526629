import "./icon.scss";

export type InitialIconTheme = "neutral" | "primary" | "info" | "brand";

export interface InitialIconProps {
  className?: string;
  fontSize?: string;
  text: string;
  theme?: InitialIconTheme;
}
export const InitialIcon = ({
  className = "",
  fontSize = "body-condensed",
  text,
  theme = "neutral",
}: InitialIconProps) => {
  const constantClasses =
    "initial-icon d-inline-flex align-items-center justify-content-center flex-shrink-0 rounded-circle";
  const classes = `${constantClasses} font-size-${fontSize} ${getThemeClasses(theme)} ${className}`;
  return (
    <span className={classes} data-testid="initial-icon">
      {text?.slice(0, 1)?.toUpperCase()}
    </span>
  );
};

export const getThemeClasses = (theme: InitialIconTheme) => {
  switch (theme) {
    case "primary":
      return "bg-primary-60 color-neutral-00";
    case "info":
      return "bg-info-50 color-neutral-00";
    case "brand":
      return "bg-wex-red color-neutral-00";
    case "neutral":
    default:
      return "bg-neutral-30 color-neutral-00";
  }
};

export default InitialIcon;
