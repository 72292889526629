import { Components } from "@mui/material/styles";

const baseStyles = {
  "label + .MuiOutlinedInput-root": {
    paddingTop: "0px",
    paddingBottom: "0px",
    ".MuiInputBase-input": {
      paddingTop: "25px",
      paddingBottom: "8px",
    },
    ".MuiInputAdornment-root.MuiInputAdornment-positionStart": {
      marginTop: "16px",
    },
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: "#FFF",
  },
  ".MuiOutlinedInput-root .MuiInputBase-input": {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
};

const FONT_SIZE_SMALL = "14px";
const smallStyles = {
  "label.MuiInputLabel-sizeSmall": {
    fontSize: FONT_SIZE_SMALL,
    transform: "translate(12px, 10px) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(12px, 4px) scale(0.75)",
    },
  },
  "label + .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    ".MuiInputBase-input": {
      paddingTop: "17px",
      paddingBottom: "3px",
    },
    ".MuiInputAdornment-root.MuiInputAdornment-positionStart, .MuiInputAdornment-root.MuiInputAdornment-positionEnd":
      {
        marginTop: FONT_SIZE_SMALL,
        "*": {
          fontSize: FONT_SIZE_SMALL,
        },
      },
  },
  ".MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiInputBase-input": {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: FONT_SIZE_SMALL,
  },
};

export const textfieldTheme: Components = {
  MuiTextField: {
    defaultProps: {
      variant: "outlined",
      InputLabelProps: { variant: "filled", required: false },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: { ...baseStyles, ...smallStyles },
    },
  },
};
