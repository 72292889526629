/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from '../models/DeleteResponse';
import type { DocumentUpdateRequest } from '../models/DocumentUpdateRequest';
import type { Project } from '../models/Project';
import type { ProjectDocument } from '../models/ProjectDocument';
import type { ProjectFinancials } from '../models/ProjectFinancials';
import type { ProjectsResponse } from '../models/ProjectsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * Gets all projects for a specific business.
     * @param id ID of the business account to fetch the projects of
     * @param limit Maximum number of projects to return
     * @param page Page to view, page size is based on limit param
     * @param sort field to sort results by, include multiple times for multiple sort directions. Valid fields are: createdat, name, lastactivityat
     * @param direction direction in which to sort fields by, include one for each field, otherwise defaults to ascending
     * @param status field to filter by project status. Valid fields are: active, inactive
     * @param name field to filter by project name
     * @returns ProjectsResponse OK
     * @throws ApiError
     */
    public static getProjects(
        id: string,
        limit?: number,
        page?: number,
        sort?: string,
        direction?: string,
        status?: string,
        name?: string,
    ): CancelablePromise<ProjectsResponse> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/project`,
            query: {
                'limit': limit,
                'page': page,
                'sort': sort,
                'direction': direction,
                'status': status,
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Creates a project
     * @param data project to create
     * @param id ID of the business account
     * @returns Project OK
     * @throws ApiError
     */
    public static postProject(
        data: Project,
        id: string,
    ): CancelablePromise<Project> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/project`,
            body: data,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets a specific project.
     * @param id ID of the business account
     * @param pid ID of the project to fetch
     * @returns Project OK
     * @throws ApiError
     */
    public static getProject(
        id: string,
        pid: string,
    ): CancelablePromise<Project> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/project/${pid}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates an existing project.
     * @param data Project update data
     * @param id ID of the business for which the project will be updated
     * @param pid ID of the project to update
     * @returns Project OK
     * @throws ApiError
     */
    public static putProject(
        data: Project,
        id: string,
        pid: string,
    ): CancelablePromise<Project> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/project/${pid}`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a new project document
     * @param id ID of the business account
     * @param pid ID of the project for which the document will be added
     * @param file Document to create
     * @returns ProjectDocument OK
     * @throws ApiError
     */
    public static postProjectDocument(
        id: string,
        pid: string,
        file: Blob,
    ): CancelablePromise<ProjectDocument> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/project/${pid}/document`,
            formData: {
                'file': file,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a project document
     * @param id ID of the business account
     * @param pid ID of the project for which the document will be updated
     * @param did ID of the document that will be updated
     * @param data Add a new document to a project
     * @returns ProjectDocument OK
     * @throws ApiError
     */
    public static putProjectDocument(
        id: string,
        pid: string,
        did: string,
        data: DocumentUpdateRequest,
    ): CancelablePromise<ProjectDocument> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/project/${pid}/document/${did}`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets financials for a specific project.
     * @param id ID of the business account
     * @param pid ID of the project to fetch
     * @returns ProjectFinancials OK
     * @throws ApiError
     */
    public static getProjectFinancials(
        id: string,
        pid: string,
    ): CancelablePromise<ProjectFinancials> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/project/${pid}/financials`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes a specific project.
     * @param id ID of the business account
     * @param pid ID of the project to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteProjectV2(
        id: string,
        pid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request({
            method: 'DELETE',
            path: `/v2/business-account/${id}/project/${pid}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes a specific project document.
     * @param id ID of the business account
     * @param pid ID of the project from which the document will be deleted
     * @param did ID of the document to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteProjectDocumentV2(
        id: string,
        pid: string,
        did: string,
    ): CancelablePromise<DeleteResponse> {
        return __request({
            method: 'DELETE',
            path: `/v2/business-account/${id}/project/${pid}/document/${did}`,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}