import { RootState, StatefulEntity } from ".";
import { logRejectedThunk } from "../sentry";
import { WritableDraft } from "immer/dist/types/types-external";

export type StandardSelector<T, Y extends StatefulEntity<T>> = (e: RootState) => Y;

export interface Identifiable {
  id?: string | number;
}

export type Writable = WritableDraft<Identifiable>;

export type ConditionResponse = (arg, thunk) => boolean | Promise<boolean>;

export const createInitialState = <T>(initial?: T): StatefulEntity<T> => ({
  data: initial || null,
  empty: true,
  loading: false,
});

export const pending = <T>(state: WritableDraft<StatefulEntity<T>>) => {
  state.loading = true;
  state.error = null;
};

export const error = <T>(state: WritableDraft<StatefulEntity<T>>, action: any) => {
  state.error = action.payload;
  state.loading = false;
  logRejectedThunk(state, action);
};

export const fulfilled = <T>(
  state: WritableDraft<StatefulEntity<T>>,
  action: any,
  emptyCondition?: (payload: T) => boolean
) => {
  state.loading = false;
  state.data = action.payload;
  state.fulfilled = Date.now();
  state.empty = emptyCondition
    ? emptyCondition(action.payload)
    : action.payload?.length !== undefined
    ? action.payload?.length <= 0
    : !action.payload;
};

export const fulfilledArray = <T>(
  state: WritableDraft<StatefulEntity<T>>,
  action: any,
  selector?: (state: WritableDraft<StatefulEntity<T>>) => WritableDraft<Identifiable>[]
) => {
  state.loading = false;
  state.fulfilled = Date.now();
  const stateSelector = (selector ? selector(state) : (state.data as any)) || [];
  const idx = stateSelector.findIndex((item) => item.id === action.payload.id);
  // Adds to end of array if item not in array already; otherwise, replaces the item in the array.
  stateSelector.splice(idx < 0 ? stateSelector.length : idx, 1, action.payload);
  state.empty = stateSelector.length <= 0;
};

export const update = fulfilledArray;

export const remove = <T>(
  state: WritableDraft<StatefulEntity<T>>,
  action: any,
  selector?: (state: WritableDraft<StatefulEntity<T>>) => WritableDraft<Identifiable>[]
) => {
  state.loading = false;
  state.fulfilled = Date.now();
  const stateSelector = selector ? selector(state) : (state.data as any);
  const idx = stateSelector.findIndex((item) => item.id === action.payload.id);
  stateSelector.splice(idx, 1);
  state.empty = stateSelector.length <= 0;
};

export const clear = <T>(state: WritableDraft<StatefulEntity<T>>) => {
  state.data = null;
  state.empty = true;
  state.error = null;
  state.fulfilled = null;
  state.loading = false;
};

export const throttleCondition = <T>(state: StatefulEntity<T>, threshold: number) =>
  state?.fulfilled + threshold > Date.now();

export const loadingCondition = <T>(state: StatefulEntity<T>) => state?.loading === false;
