/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessAccount } from '../models/BusinessAccount';
import type { PersonalInformationRequest } from '../models/PersonalInformationRequest';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Verify a business account is set up for public payments
     * @param id ID of the business account or the url-encoded flumetag
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static publicValidateHandler(
        id: string,
    ): CancelablePromise<BusinessAccount> {
        return __request({
            method: 'GET',
            path: `/v1/public/${id}/validate`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Accept terms and conditions
     * @returns User Created
     * @throws ApiError
     */
    public static acceptTerms(): CancelablePromise<User> {
        return __request({
            method: 'POST',
            path: `/v1/terms`,
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Disables a user in okta
     * @returns User OK
     * @throws ApiError
     */
    public static disableUser(): CancelablePromise<User> {
        return __request({
            method: 'PUT',
            path: `/v1/user/disable`,
            errors: {
                403: `Forbidden`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * validate a users invitation code
     * @param code invite code to apply to user
     * @returns User OK
     * @throws ApiError
     */
    public static valdiateInvitationCode(
        code: string,
    ): CancelablePromise<User> {
        return __request({
            method: 'PUT',
            path: `/v1/user/invitation-code/${code}/validate`,
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Add user personal information
     * @param data Personal Information
     * @returns User Created
     * @throws ApiError
     */
    public static personalInformation(
        data: PersonalInformationRequest,
    ): CancelablePromise<User> {
        return __request({
            method: 'PUT',
            path: `/v1/user/personal-information`,
            body: data,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Validate a user's JWT Authorization Bearer token
     * @returns User OK
     * @throws ApiError
     */
    public static validate(): CancelablePromise<User> {
        return __request({
            method: 'GET',
            path: `/v1/validate`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}