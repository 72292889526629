import { To } from "react-router-dom";
import LoadingSpinner from "./lottie/LoadingSpinner";

interface ExternalRedirectProps {
  showSpinner?: boolean;
  to: To;
}
/**
 * Component neeeded because attempts to redirect to an external URL using react router v6's <Navigate>
 * just append it to the root URL rather than taking to the external page.
 */
export const ExternalRedirect = ({ showSpinner = false, to }: ExternalRedirectProps) => {
  window.location.replace(to.toString());
  return showSpinner ? <LoadingSpinner /> : <></>;
};
