import CloseIcon from "@mui/icons-material/Close";
import Button, { ButtonSize, ButtonTheme, ButtonVariant } from "./Button";

export const CloseButton = ({ onClose }: { onClose: (event: React.MouseEvent) => void }) => (
  <Button
    attributes={{ "aria-label": "Close button", "data-testid": "close-button" }}
    onClick={onClose}
    size={ButtonSize.XSMALL}
    theme={ButtonTheme.NEUTRAL}
    variant={ButtonVariant.TERTIARY}
  >
    <CloseIcon />
  </Button>
);
