// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  Analytics,
  setCurrentScreen,
  logEvent as firebaseLogEvent,
} from "firebase/analytics";
import { getPerformance, FirebasePerformance } from "firebase/performance";
import config, { isProd } from "./config";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export type AnalyticEvent =
  | "onboard_start"
  | "onboard_finish"
  | "session_start"
  | "promo_dialog_close"
  | "promo_dialog_learn_more"
  | "promo_dialog_register"
  | "promo_banner_learn_more";

export interface FlumeFirebase {
  analytics?: Analytics;
  performance?: FirebasePerformance;
}

const flumeFirebase: FlumeFirebase = {
  analytics: null,
  performance: null,
};
// Initialize Firebase
if (isProd()) {
  const app = initializeApp(config.firebase);
  flumeFirebase.analytics = getAnalytics(app);
  // Initialize Performance Monitoring and get a reference to the service
  flumeFirebase.performance = getPerformance(app);
}

export const FirebasePageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (flumeFirebase.analytics !== null) {
      const page_path = location.pathname + location.search;
      setCurrentScreen(flumeFirebase.analytics, page_path);
      firebaseLogEvent(flumeFirebase.analytics, "page_view", { page_path });
    }
  }, [location]);

  return null;
};

export const logEvent = (name: AnalyticEvent, opts: any) => {
  return flumeFirebase?.analytics ? firebaseLogEvent(flumeFirebase.analytics, name, opts) : null;
};

export default flumeFirebase;
