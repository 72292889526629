import { Trans } from "react-i18next";
import { TranslationFunction, TranslationOptions } from "./hook";

type TranslationProps = TranslationOptions & {
  components?: Record<string, any>; // key values to replace the component slots.
  i18nKey: string; // Key to lookup the translation.
  translationFunc?: TranslationFunction; // Override default translation function used.
};

/**
 * Use for when you need to interpolate in JSX components; otherwise, just use the hook.
 */
export const Translation = ({
  components = {},
  context,
  count,
  i18nKey,
  ns,
  replace = {},
  translationFunc,
}: TranslationProps) => (
  <Trans
    components={components}
    context={context}
    count={count}
    i18nKey={i18nKey}
    ns={ns}
    t={translationFunc as any}
    values={replace}
  />
);
