/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessAccount } from '../models/BusinessAccount';
import type { BusinessAccountCreateRequest } from '../models/BusinessAccountCreateRequest';
import type { BusinessAccountUpdateRequest } from '../models/BusinessAccountUpdateRequest';
import type { FlumeBalance } from '../models/FlumeBalance';
import type { FlumeTagRequest } from '../models/FlumeTagRequest';
import type { NetworkAccount } from '../models/NetworkAccount';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class BusinessAccountService {

    /**
     * Create a new business account
     * @param data Create new business account
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static createBusinessAccount(
        data: BusinessAccountCreateRequest,
    ): CancelablePromise<BusinessAccount> {
        return __request({
            method: 'POST',
            path: `/v1/business-account`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Get a business account
     * @param id ID of the business account
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static getBusinessAccount(
        id: string,
    ): CancelablePromise<BusinessAccount> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}`,
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update a business account
     * @param data Update an existing business account
     * @param id ID of the business account
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static updateBusinessAccount(
        data: BusinessAccountUpdateRequest,
        id: string,
    ): CancelablePromise<BusinessAccount> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}`,
            body: data,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * Gets the available flume balance of a business.
     * @param id ID of the business account
     * @returns FlumeBalance OK
     * @throws ApiError
     */
    public static getBusinessAccountBalance(
        id: string,
    ): CancelablePromise<FlumeBalance> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/balance`,
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Claim a business
     * @param id ID of an existing public business accepting a business invite
     * @param code Business invite code provided to the public business
     * @returns any OK
     * @throws ApiError
     */
    public static claimBusiness(
        id: string,
        code: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/claim/${code}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * validates and sets a business's flume tag
     * @param id ID of the business account to receive the FlumeTag
     * @param data flume tag request
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static putFlumeTag(
        id: string,
        data: FlumeTagRequest,
    ): CancelablePromise<BusinessAccount> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/flume-tag`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Search for public business accounts matching FlumeTag
     * @param id ID of the business account which submits the call
     * @param flumetag FlumeTag to search on
     * @param number number of results to return, sorted bu FlumeTag.
     * @param skip number of results to skip, used for pagination. Ignored if number is not passed.
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static getByFlumeTag(
        id: string,
        flumetag: string,
        number?: number,
        skip?: number,
    ): CancelablePromise<Array<NetworkAccount>> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/flume-tag/${flumetag}`,
            query: {
                'number': number,
                'skip': skip,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Search for public business accounts by name
     * @param id ID of the business account which submits the call
     * @param search Name to search for
     * @param limit number of results to return, sorted alphabetically
     * @param skip number of results to skip, used for pagination. Ignored if number is not passed.
     * @returns NetworkAccount OK
     * @throws ApiError
     */
    public static searchBusinessAccounts(
        id: string,
        search: string,
        limit?: number,
        skip?: number,
    ): CancelablePromise<Array<NetworkAccount>> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/search`,
            query: {
                'search': search,
                'limit': limit,
                'skip': skip,
            },
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}