/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessAccount } from '../models/BusinessAccount';
import type { DeleteResponse } from '../models/DeleteResponse';
import type { Integration } from '../models/Integration';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ErpService {

    /**
     * Creates an integration
     * @param id ID of the business account
     * @returns Integration OK
     * @throws ApiError
     */
    public static postIntegration(
        id: string,
    ): CancelablePromise<Integration> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/integration`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets a specific integration.
     * @param id ID of the business account
     * @param iid ID of the integration to fetch
     * @returns Integration OK
     * @throws ApiError
     */
    public static getIntegration(
        id: string,
        iid: string,
    ): CancelablePromise<Integration> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/integration/${iid}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates a specific integration status to FlumeLinkedPending.
     * @param id ID of the business account
     * @param iid ID of the integration to fetch
     * @returns BusinessAccount OK
     * @throws ApiError
     */
    public static updateLinkedPending(
        id: string,
        iid: string,
    ): CancelablePromise<BusinessAccount> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/integration/${iid}/linked-pending`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Unlinks a specific integration.
     * @param id ID of the business account
     * @param iid ID of the integration to fetch
     * @returns any OK
     * @throws ApiError
     */
    public static unlinkIntegration(
        id: string,
        iid: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/integration/${iid}/unlink`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes a specific integration.
     * @param id ID of the business account
     * @param iid ID of the integration to delete
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteIntegrationV2(
        id: string,
        iid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request({
            method: 'DELETE',
            path: `/v2/business-account/${id}/integration/${iid}`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

}