import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { isCypress, isProd } from "../config";
import resourcesToBackend from "i18next-resources-to-backend";

/**
 * Initializes the I18n instance to be used throughout the app.
 */
export const initializeI18n = () => {
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // uses browser language detector
    .use(
      resourcesToBackend(
        // loads only the needed translations for the current language
        (language, namespace) => import(`./translation/${namespace}/${language}.json`)
      )
    )
    .init({
      supportedLngs: isProd() ? ["en"] : ["en", "es", "la"], // English is the only support language in prod for now
      defaultNS: "common",
      fallbackLng: isProd() ? "en" : false, // no fallback in dev/local for easier testing
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
      react: {
        useSuspense: isCypress() ? false : true, // for cypress tests
      },
      partialBundledLanguages: true,
      saveMissing: true,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        console.warn(
          `i18nKey not found: ${key}, language: ${lng}, namespace: ${ns}, fallbackValue: ${fallbackValue}`
        );
      },
      parseMissingKeyHandler: (key: string) => {
        return `{{i18nKey not found: "${key}"}}`;
      },
      ns: ["common", "layout"],
    });
};
