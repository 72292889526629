import "./error.scss";
import { FallbackProps } from "./FallbackProps";
import { NewBuildError } from "./NewBuildError";
import { StandardError } from "./StandardError";

export const Fallback = (props: FallbackProps) => {
  const isChunkError = props.error.name === "ChunkLoadError";

  return isChunkError ? <NewBuildError {...props} /> : <StandardError {...props} />;
};
