/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExternalAccount = {
    institution_name?: string;
    logo?: string;
    mask?: string;
    name?: string;
    primary_color?: string;
    status?: ExternalAccount.status;
    subtype?: string;
    type?: string;
}

export namespace ExternalAccount {

    export enum status {
        CONNECTED = 'connected',
        REAUTHENTICATE = 'reauthenticate',
    }


}
