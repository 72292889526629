import { useTranslation } from "react-i18next";

// Return any type because can be string, array, or object depending on key.
export type TranslationFunction = (key: string, options?: TranslationOptions) => any;

// Can start with the following options, and then add on if more options are needed as we use it.
export type TranslationOptions = {
  context?: string; // Contextual variant of the key to use.
  count?: number; // Count value used for plurals.
  fallbacks?: string[]; // List of keys to fallback to if given key doesn't work.
  ns?: string; // Namespace to get key from.
  returnObjects?: boolean; // Allow return of arrays/objects instead of just strings.
  replace?: Record<string, string | number>; // key values to replace interpolated items in the translation.
  defaultValue?: string; //default value to return if a translation was not found
};

/**
 * Hook to get the function to lookup keys in the translation service.
 * @param options options for when retrieving translations.
 * @param options.namepsaces the namespaces to load.
 * @param options.prefix a prefix to prepend to all keys.
 * @returns the translation lookup function.
 */
export const useI18n = (options?: {
  namespaces?: string[];
  prefix?: string;
}): TranslationFunction => {
  return useTranslation(options?.namespaces || undefined, { keyPrefix: options?.prefix || "" }).t;
};
