/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IDVLinkToken } from '../models/IDVLinkToken';
import type { LinkToken } from '../models/LinkToken';
import type { PublicToken } from '../models/PublicToken';
import type { SessionID } from '../models/SessionID';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class PlaidService {

    /**
     * Create a plaid idv link token
     * @param id ID of the business account
     * @param device The device type, web or ios
     * @returns IDVLinkToken OK
     * @throws ApiError
     */
    public static createPlaidIdvLinkToken(
        id: string,
        device?: 'web' | 'ios',
    ): CancelablePromise<IDVLinkToken> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/account-verification/plaid/idv/create-token/${device}`,
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Set the plaid idv user session
     * @param data plaid session id
     * @param id ID of the business account
     * @returns User OK
     * @throws ApiError
     */
    public static setPlaidIdvUserSession(
        data: SessionID,
        id: string,
    ): CancelablePromise<User> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/account-verification/plaid/idv/set-user-idv`,
            body: data,
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create a plaid link token
     * @param id ID of the business account
     * @param device The device type, web or ios
     * @returns LinkToken OK
     * @throws ApiError
     */
    public static createPlaidLinkToken(
        id: string,
        device?: 'web' | 'ios',
    ): CancelablePromise<LinkToken> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/plaid/create-token/${device}`,
            errors: {
                401: `Unauthorized`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Set plaid access token
     * @param data plaid public token
     * @param id ID of the business account
     * @returns User OK
     * @throws ApiError
     */
    public static setPlaidAccessToken(
        data: PublicToken,
        id: string,
    ): CancelablePromise<User> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/plaid/set-access-token`,
            body: data,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}