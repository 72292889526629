import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { PublicReducerType } from "../publicSlice";
import {
  CheckoutLink,
  PublicInvoice,
  PublicPayRequest,
  PaymentService,
  InvoiceService,
} from "../../../generated/openapi";
import { RootState } from "../../../store";
import { returnWithErrorWrap } from "../../../store/error";
import { clear, error, fulfilled, pending } from "../../../store/reducer";

export const createPayment = createAsyncThunk<
  CheckoutLink,
  { data: PublicPayRequest; payeeId: string }
>("public/pay/create", async (args, thunk) =>
  returnWithErrorWrap(() => PaymentService.publicCreatePayment(args.data, args.payeeId), thunk)
);
export const createInvoicePayment = createAsyncThunk<
  CheckoutLink,
  { payeeId: string; data: PublicPayRequest; invoiceId: string }
>("public/pay/invoice/create", async (args, thunk) =>
  returnWithErrorWrap(
    () => PaymentService.publicPayInvoice(args.payeeId, args.data, args.invoiceId),
    thunk
  )
);
export const getPaymentInvoice = createAsyncThunk<
  PublicInvoice,
  { invoiceId: string; payeeId: string }
>("public/pay/invoice/get", async (args, thunk) =>
  returnWithErrorWrap(() => InvoiceService.publicGetInvoice(args.payeeId, args.invoiceId), thunk)
);
export const clearPaymentInvoice = createAsyncThunk("public/pay/invoice/clear", async () => {});
export const clearPaymentLink = createAsyncThunk("public/pay/clear", async () => {});

export const selectPaymentInvoiceState = (state: RootState) => state.public.paymentInvoice;
export const selectPaymentLinkState = (state: RootState) => state.public.paymentLink;

export const buildPublicPaymentReducers = (builder: ActionReducerMapBuilder<PublicReducerType>) => {
  builder.addCase(createInvoicePayment.pending, (state) => pending(state.paymentLink));
  builder.addCase(createInvoicePayment.fulfilled, (state, action) =>
    fulfilled(state.paymentLink, action)
  );
  builder.addCase(createInvoicePayment.rejected, (state, action) =>
    error(state.paymentLink, action)
  );

  builder.addCase(createPayment.pending, (state) => pending(state.paymentLink));
  builder.addCase(createPayment.fulfilled, (state, action) => fulfilled(state.paymentLink, action));
  builder.addCase(createPayment.rejected, (state, action) => error(state.paymentLink, action));

  builder.addCase(getPaymentInvoice.pending, (state) => pending(state.paymentInvoice));
  builder.addCase(getPaymentInvoice.fulfilled, (state, action) =>
    fulfilled(state.paymentInvoice, action)
  );
  builder.addCase(getPaymentInvoice.rejected, (state, action) =>
    error(state.paymentInvoice, action)
  );

  builder.addCase(clearPaymentInvoice.pending, (state) => clear(state.paymentInvoice));
  builder.addCase(clearPaymentLink.pending, (state) => clear(state.paymentLink));
};
