import { LinearProgress, LinearProgressProps } from "@mui/material";
import { useEffectOnce } from "flume/shared/hook";
import { useCallback, useState } from "react";
import { useInterval } from "../hook";

export const TimedLinearProgress = ({
  start,
  step,
  delay,
  onFinish,
  ...props
}: { start: number; step: number; delay: number; onFinish: () => void } & LinearProgressProps) => {
  const [val, setValue] = useState(start);
  const cb = useCallback(() => {
    if (val >= 100) return;
    setValue((v) => v + step);
  }, [step, val]);
  const cancelInterval = useInterval(cb, delay);

  useEffectOnce(
    useCallback(() => val >= 100, [val]),
    useCallback(() => {
      cancelInterval();
      onFinish();
    }, [onFinish, cancelInterval])
  );

  return <LinearProgress {...props} value={val} />;
};
