import { Components } from "@mui/material/styles";
import colors from "../theme/colors.module.scss";

export const tabTheme: Components = {
  MuiTab: {
    styleOverrides: {
      root: {
        color: colors["neutral-60"],
        fontWeight: 300,
        textTransform: "none",
        "&.Mui-selected": {
          color: colors["neutral-70"],
          fontWeight: 500
        },
        "&:hover": {
          borderRadius: "10px 10px 0  0",
          backgroundColor:  colors["neutral-05"],
  
        }
      }
    }
  }
};
