/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoutingNumber } from '../models/RoutingNumber';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RoutingNumberService {

    /**
     * Retrieve an exact routing number
     * @param routingNumber routing number to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static publicHandleGetRoutingNumber(
        routingNumber: string,
    ): CancelablePromise<RoutingNumber> {
        return __request({
            method: 'GET',
            path: `/v1/public/routing-number/${routingNumber}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieve routing numbers with matched prefix
     * @param routingNumber routing prefix to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static publicHandleGetRoutingNumbers(
        routingNumber: string,
    ): CancelablePromise<Array<RoutingNumber>> {
        return __request({
            method: 'GET',
            path: `/v1/public/routing-numbers/${routingNumber}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieve an exact routing number
     * @param routingNumber routing number to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static getRoutingNumber(
        routingNumber: string,
    ): CancelablePromise<RoutingNumber> {
        return __request({
            method: 'GET',
            path: `/v1/routing-number/${routingNumber}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Retrieve routing numbers with matched prefix
     * @param routingNumber routing prefix to retrieve
     * @returns RoutingNumber OK
     * @throws ApiError
     */
    public static getRoutingNumbers(
        routingNumber: string,
    ): CancelablePromise<Array<RoutingNumber>> {
        return __request({
            method: 'GET',
            path: `/v1/routing-numbers/${routingNumber}`,
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}