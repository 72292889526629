/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Invoice } from '../models/Invoice';
import type { InvoiceUpdateRequest } from '../models/InvoiceUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class EstimateService {

    /**
     * Convert an estimate to an invoice
     * @param id ID of the business account
     * @param iid ID of the estimate to convert
     * @returns Invoice OK
     * @throws ApiError
     */
    public static convertToInvoice(
        id: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/estimate/${iid}/convert`,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Send a notification for an estimate
     * @param id ID of the business who owns the estimate
     * @param iid ID of the estimate to send notification for
     * @returns Invoice OK
     * @throws ApiError
     */
    public static sendEstimateNotification(
        id: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/estimate/${iid}/notify`,
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates the status on an existing estimate.
     * @param data Estimate update data
     * @param id ID of the business for which the estimate will be updated
     * @param iid ID of the estimate to update
     * @returns Invoice OK
     * @throws ApiError
     */
    public static putEstimateStatus(
        data: InvoiceUpdateRequest,
        id: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/estimate/${iid}/status`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Public approve or reject an existing estimate.
     * @param data Invoice update data
     * @param id ID of the business account
     * @param iid ID of the estimate to approve or reject
     * @returns Invoice OK
     * @throws ApiError
     */
    public static putEstimateApprove(
        data: InvoiceUpdateRequest,
        id: string,
        iid: string,
    ): CancelablePromise<Invoice> {
        return __request({
            method: 'PUT',
            path: `/v1/public/business-account/${id}/estimate/${iid}/approve`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}