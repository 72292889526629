import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Integration,
  ErpService,
  BusinessAccountErpService,
  NetworkAccount,
  ScrubMatchRequest,
  BusinessAccount,
} from "../../generated/openapi";
import { ErpSupplier } from "../../generated/openapi/models/ErpSupplier";
import { logRejectedThunk } from "../../sentry";
import { RootState, StatefulEntity } from "../../store";
import { returnWithErrorWrap } from "../../store/error";
import { selectBusinessAccounts } from "../business/businessSlice";
import { updateNetworkAccountLocal } from "../payee/payeeSlice";

export enum IntegrationStatus {
  STARTED = "FlumeStarted",
  PENDING = "FlumeLinkedPending",
  LINKED = "Linked",
  DEAUTHORIZED = "Deauthorized",
  UNLINKED = "Unlinked",
}
//only linked or deauthorized
export const isErpConnected = (account: BusinessAccount) => {
  return (
    account?.erp?.status === IntegrationStatus.LINKED ||
    account?.erp?.status === IntegrationStatus.DEAUTHORIZED
  );
};

export const isErpSupplier = (option: NetworkAccount | ErpSupplier): boolean => {
  return (option as NetworkAccount)?.from_erp === undefined && option !== null;
};

export const createIntegration = createAsyncThunk<Integration, string, {}>(
  "erp/integration/create",
  (arg, thunk) => {
    return returnWithErrorWrap(() => ErpService.postIntegration(arg), thunk);
  }
);

export const unlinkIntegration = createAsyncThunk<any, { id: string; iid: string }, {}>(
  "erp/integration/unlink",
  (args, thunk) => returnWithErrorWrap(() => ErpService.unlinkIntegration(args.id, args.iid), thunk)
);

export const getErpSuppliers = createAsyncThunk<ErpSupplier[], { id: string }, {}>(
  "erp/supplier/get",
  (arg, thunk) => {
    return returnWithErrorWrap(
      () => BusinessAccountErpService.getSuppliersReturnSuppliers(arg.id),
      thunk
    );
  }
);

export const matchSupplier = createAsyncThunk<NetworkAccount, ScrubMatchRequest, {}>(
  "erp/match",
  (arg, thunk) => {
    return returnWithErrorWrap(async () => {
      const networkAccount = await BusinessAccountErpService.matchAccount(arg);
      thunk.dispatch(updateNetworkAccountLocal(networkAccount));
      thunk.dispatch(getErpSuppliers({ id: arg.business_account_id }));
      return networkAccount;
    }, thunk);
  }
);

export const createSupplierId = createAsyncThunk<
  NetworkAccount,
  { accountId: string; networkId: string },
  {}
>("erp/create/id", (arg, thunk) => {
  return returnWithErrorWrap(async () => {
    const networkAccount = await BusinessAccountErpService.createErpEntity(
      arg.accountId,
      arg.networkId
    );
    thunk.dispatch(updateNetworkAccountLocal(networkAccount));
    return networkAccount;
  }, thunk);
});

export const findNetworkAccountsFromSupplier = createAsyncThunk<
  NetworkAccount[],
  { accountId: string; supplierId: string },
  {}
>("erp/supplier/findNetwork", (arg, thunk) => {
  return returnWithErrorWrap(
    () => BusinessAccountErpService.findNetworkAccountsFromSupplier(arg.accountId, arg.supplierId),
    thunk
  );
});

export const findSupplierFromNetworkAccount = createAsyncThunk<
  ErpSupplier[],
  { accountId: string; networkId: string },
  {}
>("erp/supplier/findSupplier", (arg, thunk) => {
  return returnWithErrorWrap(
    () => BusinessAccountErpService.findSupplierFromNetworkAccount(arg.accountId, arg.networkId),
    thunk
  );
});

export const selectIntegrationsState = (state: RootState) => state.erp.integrations;
export const selectIntegrations = (state: RootState): Integration[] => state.erp.integrations.data;
export const selectUnlinkState = (state: RootState) => state.erp.unlink;
export const selectBusinessIntegration = (state: RootState): Integration =>
  selectBusinessAccounts(state)[0]?.erp;
export const selectErpSuppliers = (state: RootState): NetworkAccount[] => state.erp.suppliers.data;
export const selectErpSupplierState = (state: RootState): StatefulEntity<NetworkAccount[]> =>
  state.erp.suppliers;
export const selectSupplierMatches = (state: RootState): StatefulEntity<ErpSupplier[]> =>
  state.erp.supplierMatches;
export const selectNetworkAccountMatches = (state: RootState): StatefulEntity<NetworkAccount[]> =>
  state.erp.networkMatches;
const integrations: StatefulEntity<Integration[]> = {
  empty: false,
  data: [],
  loading: false,
  error: null,
};
const unlink: StatefulEntity<string> = {
  data: "",
  empty: false,
  loading: false,
};

const suppliers: StatefulEntity<NetworkAccount[]> = {
  data: [],
  empty: false,
  loading: false,
};

const supplierMatches: StatefulEntity<ErpSupplier[]> = {
  data: [],
  empty: false,
  loading: false,
};

const networkMatches: StatefulEntity<NetworkAccount[]> = {
  data: [],
  empty: false,
  loading: false,
};

export const erpSlice = createSlice({
  name: "erp",
  initialState: {
    integrations,
    unlink,
    suppliers,
    supplierMatches,
    networkMatches,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createIntegration.pending, (state, action) => {
      state.integrations.loading = true;
      state.integrations.error = null;
    });
    builder.addCase(createIntegration.fulfilled, (state, action) => {
      state.integrations.loading = false;
      state.integrations.data = [
        ...state.integrations.data.filter((item) => item.id !== action.payload.id),
        action.payload,
      ];
    });
    builder.addCase(createIntegration.rejected, (state, action) => {
      state.integrations.error = action.payload;
      state.integrations.loading = false;
    });

    builder.addCase(unlinkIntegration.pending, (state) => {
      state.unlink.data = "";
      state.unlink.error = null;
      state.unlink.loading = true;
    });
    builder.addCase(unlinkIntegration.fulfilled, (state, action) => {
      state.unlink.data = action.meta.arg.id;
      state.unlink.loading = false;
    });
    builder.addCase(unlinkIntegration.rejected, (state, action) => {
      state.unlink.data = action.meta.arg.id;
      state.unlink.error = action.error;
      state.unlink.loading = false;
    });

    builder.addCase(getErpSuppliers.pending, (state, action) => {
      state.suppliers.loading = true;
      state.suppliers.error = null;
    });

    builder.addCase(getErpSuppliers.fulfilled, (state, action) => {
      state.suppliers.loading = false;
      state.suppliers.data = action.payload;
      state.suppliers.empty = action.payload?.length <= 0;
    });

    builder.addCase(getErpSuppliers.rejected, (state, action) => {
      state.suppliers.error = action.payload;
      state.suppliers.loading = false;
      logRejectedThunk(state, action);
    });

    builder.addCase(matchSupplier.fulfilled, (state, action) => {
      const idx = state.suppliers.data.findIndex(
        (item) => item.id === action.payload.erp_supplier_id
      );
      const supplier = state.suppliers.data[idx];
      state.suppliers.data.splice(idx, 1, { ...supplier, matched: true });
    });

    builder.addCase(findNetworkAccountsFromSupplier.pending, (state, action) => {
      state.networkMatches.loading = true;
      state.networkMatches.error = null;
    });

    builder.addCase(findNetworkAccountsFromSupplier.fulfilled, (state, action) => {
      state.networkMatches.loading = false;
      state.networkMatches.data = action.payload;
      state.networkMatches.empty = action.payload?.length <= 0;
    });

    builder.addCase(findNetworkAccountsFromSupplier.rejected, (state, action) => {
      state.networkMatches.loading = false;
      state.networkMatches.error = action.payload;
    });

    builder.addCase(findSupplierFromNetworkAccount.pending, (state, action) => {
      state.supplierMatches.loading = true;
      state.supplierMatches.error = null;
    });

    builder.addCase(findSupplierFromNetworkAccount.fulfilled, (state, action) => {
      state.networkMatches.loading = false;
      state.networkMatches.data = action.payload;
      state.networkMatches.empty = action.payload?.length <= 0;
    });

    builder.addCase(findSupplierFromNetworkAccount.rejected, (state, action) => {
      state.networkMatches.loading = false;
      state.networkMatches.error = action.payload;
    });
  },
});

export default erpSlice.reducer;
