import { useCallback, useEffect, useRef, useMemo, useState } from "react";
import { CancelablePromise } from "../generated/openapi";
import { CurrencyFormatOptions } from "./format";
import { get } from "lodash";
import { FieldErrors, FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";
import { StatefulEntity } from "flume/store";
export const usePrevious = <T = any>(value: T): T => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
/**
 *
 * @param condition A function to determine when it's time to execute the func.
 * @param func A function that does the effect once.  Typically dispatching an action to fetch data.
 * @param deps Deprecating deps it breaks the static evaluation for react/eslint.  It's better to use the existing parameters
 * that are wrapped in their own useCallbacks.
 */
export const useEffectOnce = (condition: () => boolean, func: () => any, deps?: Array<any>) => {
  const hasCalled = useRef(false);
  useEffect(() => {
    if (!hasCalled.current && condition()) {
      func();
      hasCalled.current = true;
    }
  }, [hasCalled, condition, func]);
};

export const useFormatCurrency = (opt: CurrencyFormatOptions = { showCents: true }) => {
  const ref = useRef(
    new Intl.NumberFormat(opt?.locale || "en-US", {
      style: "currency",
      currency: opt?.currency || "USD",
      minimumFractionDigits: opt.showCents === true ? 2 : 0,
    })
  );

  return useCallback(
    (amount: number) => {
      let num = amount || 0;
      num = opt.normalized ? num / 100 : num;
      return ref.current.format(num);
    },
    [ref, opt.normalized]
  );
};
/**
 * This hook helps clean up the cancellable promises that the Service layer creates.
 * Using this reduces the boilerplate of keeping a reference to the last service call.
 * @param A function to fetch API data
 * @returns A callable function that returns a Promise to return data
 */
export function useServiceCallback<T, Y>(func: (val: Y) => CancelablePromise<T>) {
  const request = useRef<CancelablePromise<T>>(null);
  useEffect(() => {
    return () => request.current?.cancel();
  }, [func, request]);

  const service = useCallback(
    (val: Y) => {
      request.current?.cancel();
      request.current = func(val);
      return request.current;
    },
    [func, request]
  );
  return service;
}

// Utility type to extract the inner type from a CancelablePromise
type ExtractPromiseType<T> = T extends CancelablePromise<infer R> ? R : never;

export type Service = (...args: any[]) => any;

export type ServiceStateOptions<X extends Service, Y = ExtractPromiseType<ReturnType<X>>> = {
  isEmpty?: (data: Y) => boolean;
  reducer?: <Z>(data: Y) => Z;
  initial?: StatefulEntity<Y>;
};
const isEmpty = (data: any) => data?.length === 0;

/**
 * This hook helps clean up the cancellable promises that the Service layer creates.
 * Using this reduces the boilerplate of keeping a reference to the last service call.
 * @param A function to fetch API data
 * @param opts Options to customize the behavior of the service
 * @returns A callable function that returns a Promise to return data
 */
export function useServiceState<T extends Service>(
  func: T,
  opts?: ServiceStateOptions<T>
): [
  StatefulEntity<ExtractPromiseType<ReturnType<typeof func>>>,
  (...val: Parameters<typeof func>) => ReturnType<T>
] {
  const request = useRef<ReturnType<typeof func>>(null);
  const [state, setState] = useState<StatefulEntity<ExtractPromiseType<ReturnType<typeof func>>>>(
    opts?.initial || {
      loading: false,
      data: null,
      fulfilled: null,
      empty: true,
    }
  );
  useEffect(() => {
    return () => request.current?.cancel();
  }, [func, request]);

  const service = useCallback(
    (...val: Parameters<typeof func>) => {
      request?.current?.cancel();
      setState((prev) => ({ ...prev, loading: true }));
      request.current = func.apply(null, val);
      return request.current
        .then((res) => {
          setState({
            loading: false,
            data: opts?.reducer ? opts?.reducer(res) : res,
            fulfilled: Date.now(),
            empty: opts?.isEmpty ? opts.isEmpty(res) : isEmpty(res),
          });
          return res;
        })
        .catch((err) => {
          setState((prev) => ({ ...prev, loading: false, error: err }));
          return err;
        });
    },
    [func, request, setState, opts]
  );

  return [state, service];
}

export function useRegister<T = FieldValues>(
  register: UseFormRegister<T>,
  errors: FieldErrors<T>,
  prefix?: string
) {
  return useCallback(
    (name: any, opts?: RegisterOptions<T, any>) => {
      name = prefix ? `${prefix}.${name}` : name;
      const { ref, ...rest } = register(name, opts);
      return {
        inputRef: ref,
        error: Boolean(get(errors, name)),
        helperText: get(errors, name)?.message,
        ...rest,
      };
    },
    [register, errors, prefix]
  );
}

/**
 * This hook helps to debug dependencies so you can easily see which one is triggering an update.
 * It will log to the console which dependency changes, its old value, and its new value.
 * @param inputProps An object of dependencies to watch.
 * Example of use: `useDependenciesDebugger({ dep1, dep2, dep3 });`
 */
export const useDependenciesDebugger = (inputProps) => {
  const oldInputsRef = useRef(inputProps);
  const inputValuesArray = Object.values(inputProps);
  const inputKeysArray = Object.keys(inputProps);
  useMemo(() => {
    const oldInputs = oldInputsRef.current;

    inputKeysArray.forEach((key) => {
      const oldInput = oldInputs[key];
      const newInput = inputProps[key];
      if (oldInput !== newInput) {
        console.log("DEPENDENCY CHANGED: %s \nOLD: %o \nNEW: %o", key, oldInput, newInput);
      }
    });

    oldInputsRef.current = inputProps;
  }, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
};
