/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OnboardingStatus = {
    additional_information?: string;
    date?: string;
    status?: OnboardingStatus.status;
}

export namespace OnboardingStatus {

    export enum status {
        COMPLETE = 'complete',
        NOT_STARTED = 'not_started',
        IN_PROGRESS = 'in_progress',
        DENIED = 'denied',
        MANUAL = 'manual',
        IGNORED = 'ignored',
        PENDING_AUTOMATIC_VERIFICATION = 'pending_automatic_verification',
        PENDING_MANUAL_DEPOSIT = 'pending_manual_deposit',
        PENDING_MANUAL_VERIFICATION = 'pending_manual_verification',
        AUTOMATICALLY_VERIFIED = 'automatically_verified',
        MANUALLY_VERIFIED = 'manually_verified',
        VERIFICATION_EXPIRED = 'verification_expired',
        VERIFICATION_FAILED = 'verification_failed',
        ONBOARDING = 'onboarding',
        VERIFYING = 'verifying',
        DATA_REQUEST = 'data_request',
    }


}
