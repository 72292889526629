import config, { isProd } from "./config";
import ReactPixel from "react-facebook-pixel";

export const registerMetaPixel = () => {
  if (isProd()) {
    ReactPixel.init(config.facebook?.pixelID, null, config.facebook?.options);
    ReactPixel.pageView(); // For tracking page view
    ReactPixel.fbq("track", "PageView");
  }
};

export const metaTrack = (title: string, data: any): void => {
  ReactPixel.track(title, data);
};

export default ReactPixel;
