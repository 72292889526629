export const cleanObject = (obj: Object, keys: string[], replacement: string = "REMOVED") => {
  if (!obj) return obj;

  const cleaned = { ...obj };
  for (const key of keys) {
    if (cleaned[key]) {
      if (replacement) {
        cleaned[key] = replacement;
      } else {
        delete cleaned[key];
      }
    }
  }

  for (const key of Object.keys(cleaned)) {
    if (cleaned[key] && typeof cleaned[key] === "object" && !Array.isArray(cleaned[key])) {
      cleaned[key] = cleanObject(cleaned[key], keys, replacement);
    }
  }

  return cleaned;
};
