import { createAction, createSlice } from "@reduxjs/toolkit";
import type { RootState, StatefulEntity } from "../../store";

export const setYoutubeApiLoading = createAction("youtube-api/load");
export const setYoutubeApiLoaded = createAction("youtube-api/complete");
export const selectYoutubeApiState = (state: RootState) => state.youtube;

const youtubeSlice = createSlice({
  name: "youtube",
  initialState: {
    empty: true,
    loading: false,
  } as StatefulEntity<boolean>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setYoutubeApiLoading, (state) => {
      state.empty = true;
      state.loading = true;
    });
    builder.addCase(setYoutubeApiLoaded, (state) => {
      state.empty = false;
      state.loading = false;
    });
  },
});

export default youtubeSlice.reducer;
