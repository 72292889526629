import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NetworkAccount, BusinessAccountService, DeleteResponse } from "../../../generated/openapi";
import { logRejectedThunk } from "../../../sentry";
import { RootState } from "../../../store";
import { returnWithErrorWrap } from "../../../store/error";
import { createInitialState } from "flume/store/reducer";
import { resetStore } from "flume/store/slice";

export const searchBusiness = createAsyncThunk<NetworkAccount[], { id: string; tag: string }, {}>(
  "businessSearch/search",
  (arg, thunk) => {
    return returnWithErrorWrap(
      async () => BusinessAccountService.searchBusinessAccounts(arg.id, arg.tag),
      thunk
    );
  }
);

export const clearSearchBusiness = createAsyncThunk<NetworkAccount[], void, {}>(
  "businessSearch/clear",
  (arg, thunk) => {
    return [];
  }
);

export const updateSearchResult = createAsyncThunk<NetworkAccount, NetworkAccount, {}>(
  "businessSearch/update",
  (arg, thunk) => {
    return arg;
  }
);
export const markSearchResultRemoved = createAsyncThunk<DeleteResponse, DeleteResponse, {}>(
  "businessSearch/remove/network",
  (arg, thunk) => {
    return arg;
  }
);

export const selectSearchResultState = (state: RootState) => state.businessSearch;

export const selectSearchResults = (state: RootState) => state.businessSearch.data;

const businessSearchSlice = createSlice({
  name: "businessSearch",
  initialState: createInitialState<NetworkAccount[]>([]),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchBusiness.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(searchBusiness.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.empty = action.payload.length === 0;
    });

    builder.addCase(searchBusiness.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      logRejectedThunk(state, action);
    });

    builder.addCase(clearSearchBusiness.fulfilled, (state, action) => {
      state.loading = false;
      state.empty = false;
      state.data = action.payload;
    });

    builder.addCase(updateSearchResult.fulfilled, (state, action) => {
      const idx = state.data.findIndex((item) => item.id === action.payload.id);
      if (idx > -1) {
        state.data.splice(idx, 1, action.payload);
      }
    });

    builder.addCase(markSearchResultRemoved.fulfilled, (state, action) => {
      const networkAccount = state.data.find((item) => item.id === action.payload.id);
      if (networkAccount) {
        networkAccount.in_network = false;
      }
    });

    builder.addCase(resetStore, () => createInitialState<NetworkAccount[]>([]));
  },
});

export default businessSearchSlice.reducer;
