import { Button, styled } from "@mui/material";
import { ButtonSize, ButtonTheme, ButtonVariant } from "./Button";
import wexColors from "../theme/theme-colors.module.scss";

const getButtonPadding = (size: ButtonSize) => {
  switch (size) {
    case ButtonSize.LARGE:
      return "16px";
    case ButtonSize.MEDIUM:
      return "12px 16px";
    case ButtonSize.SMALL:
      return "8px 16px";
    case ButtonSize.XSMALL:
      return "4px 9px";
    default:
      return "";
  }
};

interface StyleOptions {
  size: ButtonSize;
  theme: ButtonTheme;
  rounded: boolean;
  variant: ButtonVariant;
  width?: string;
}
const createStyledButton = ({ size, theme, rounded, variant, width }: StyleOptions) =>
  styled(Button)(({ theme: muiTheme }) => {
    const isPrimary = variant === ButtonVariant.PRIMARY;

    const colors = muiTheme.palette[theme];
    const disabledColor = wexColors["disabled-main"];
    const disabledBgColor = wexColors["disabled-bg"];
    const disabledBorderColor = wexColors["disabled-border"];

    const widthStyles = width ? { width: "100%", maxWidth: width } : {};

    return {
      borderRadius: rounded ? "24px" : "8px",
      boxShadow: "none",
      ...widthStyles,
      minWidth: "unset",
      padding: getButtonPadding(size),
      fontFamily: ["sans-serif", "Roboto", "Inter"],
      fontSize: size === ButtonSize.LARGE ? "16px" : "14px",
      fontWeight: "500",
      textTransform: "none",

      "*": {
        color: "inherit",
        fontSize: "inherit",
        letterSpacing: "inherit",
        lineHeight: "inherit",
      },
      "&.icon-top": {
        display: "block",
        textAlign: "center",
      },
      "&.icon-top .MuiButton-startIcon": {
        lineHeight: "10px",
        marginRight: "0px",
      },

      "& .MuiButton-startIcon > *:nth-of-type(1), & .MuiButton-endIcon > *:nth-of-type(1)": {
        // Makes sure that the button icon matches font size of label.
        fontSize: "inherit",
      },

      "&:focus": {
        boxShadow: `0px 0px 0px 2px ${wexColors["active-main"]}`,
      },

      "&:hover": {
        backgroundColor: isPrimary ? colors.dark : colors.light,
        color: isPrimary ? colors.contrastText : colors.dark,
        boxShadow: "none",
        ...(!isPrimary ? { borderColor: colors.dark } : {}),
      },

      "&[disabled]": {
        borderColor: disabledBorderColor,
        backgroundColor: isPrimary ? disabledBgColor : "transparent",
        color: disabledColor,
      },

      svg: {
        height: "16px !important",
        width: "16px !important",
        fill: "currentColor",
      },

      "&.loading svg": {
        animation: "spin 1s linear",
        animationIterationCount: "infinite",
        fill: "transparent",
      },

      "@keyframes spin": {
        "100%": {
          transform: "rotate(360deg)",
        },
      },
    };
  });

export default createStyledButton;
