import "./error.scss";
import { Button, ButtonSize, ButtonTheme, ButtonVariant } from "../../component/button";
import { useBodyClass } from "../../component/hook";

export const ErrorNotFound = ({ className = "", dark }: { className?: string; dark?: boolean }) => {
  useBodyClass(`body-${dark ? "dark" : "light"}`);

  return (
    <div className={`error-layout text-center ${className}`} data-testid="404">
      <div>
        <h1 className="font-weight-semibold font-size-hero-1">Oops, page not found</h1>
        <h3 className="mb-0">This page doesn't exist or was removed!</h3>
        <h3 className="mb-3">We suggest you go back.</h3>
        <Button
          size={ButtonSize.SMALL}
          theme={dark ? ButtonTheme.SECONDARY : ButtonTheme.NEUTRAL}
          to="/"
          variant={ButtonVariant.SECONDARY}
        >
          Go back
        </Button>
      </div>
      <img alt="404" className="error-image" src="/img/404.svg" />
    </div>
  );
};
