import { configureStore, ThunkAction, Action, SerializedError } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import youtubeReducer from "../component/video/youtubeSlice";
import userReducer from "../module/user/userSlice";
import businessReducer from "../module/business/businessSlice";
import businessConfigReducer from "../module/business/businessConfigSlice";
import fuelReducer from "../module/fuel/fuelSlice";
import payeeReducer from "../module/payee/payeeSlice";
import paymentReducer from "../module/payment/paymentSlice";
import portalReducer from "../module/payment/portal/portalSlice";
import notificationReducer from "../module/notification/notificationSlice";
import businessSearchReducer from "../module/business/search/businessSearchSlice";
import planReducer from "../module/subscription/planSlice";
import erpReducer from "../module/erp/erpSlice";
import publicReducer from "../module/public/publicSlice";
import projectReducer from "../module/project/projectSlice";
import documentReducer from "../module/document/documentSlice";
import itemReducer from "../module/item/itemSlice";
import invoiceReducer from "../module/payment/invoice/invoiceSlice";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    user: userReducer,
    business: businessReducer,
    businessConfig: businessConfigReducer,
    businessSearch: businessSearchReducer,
    fuel: fuelReducer,
    payee: payeeReducer,
    payment: paymentReducer,
    invoice: invoiceReducer,
    notification: notificationReducer,
    plan: planReducer,
    youtube: youtubeReducer,
    erp: erpReducer,
    project: projectReducer,
    item: itemReducer,
    document: documentReducer,
    portal: portalReducer,
    public: publicReducer,
  },
  enhancers: [sentryReduxEnhancer],
});

export interface StatefulEntity<T> {
  loading: boolean;
  empty: boolean;
  error?: SerializedError;
  data?: T;
  fulfilled?: number;
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
