/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NetworkProject = {
    color?: string;
    id?: string;
    name?: string;
    status?: NetworkProject.status;
}

export namespace NetworkProject {

    export enum status {
        ACTIVE = 'active',
        INACTIVE = 'inactive',
    }


}
