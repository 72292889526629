/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StripeLinkResponse } from '../models/StripeLinkResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CardService {

    /**
     * Stard onboarding stripe
     * @param id ID of the business account or the url-encoded flumetag
     * @returns StripeLinkResponse OK
     * @throws ApiError
     */
    public static onboardStripe(
        id: string,
    ): CancelablePromise<StripeLinkResponse> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/onboard/stripe`,
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}