/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteResponse } from '../models/DeleteResponse';
import type { DocumentType } from '../models/DocumentType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DocumentTypeService {

    /**
     * Gets business account document types
     * @param id ID of the business account
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static getBusinessAccountDocumentTypes(
        id: string,
    ): CancelablePromise<Array<DocumentType>> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/document-type`,
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Creates a business account document type
     * @param data document type to create
     * @param id ID of the business account
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static postBusinessAccountDocumentType(
        data: DocumentType,
        id: string,
    ): CancelablePromise<DocumentType> {
        return __request({
            method: 'POST',
            path: `/v1/business-account/${id}/document-type`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets business account document types merged with default document types
     * @param id ID of the business account
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static getBusinessAccountDocumentTypesMerged(
        id: string,
    ): CancelablePromise<Array<DocumentType>> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/document-type-merged`,
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets one default document type
     * @param id ID of the business account
     * @param dtid ID of the document type
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static getBusinessAccountDocumentType(
        id: string,
        dtid: string,
    ): CancelablePromise<DocumentType> {
        return __request({
            method: 'GET',
            path: `/v1/business-account/${id}/document-type/${dtid}`,
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Updates a business account document type
     * @param data document type to update
     * @param id ID of the business account
     * @param dtid ID of the document type
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static putBusinessAccountDocumentType(
        data: DocumentType,
        id: string,
        dtid: string,
    ): CancelablePromise<DocumentType> {
        return __request({
            method: 'PUT',
            path: `/v1/business-account/${id}/document-type/${dtid}`,
            body: data,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets default document types
     * @param businessAccountId Return only custom document types created for this business account ID
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static getDefaultDocumentTypes(
        businessAccountId?: string,
    ): CancelablePromise<Array<DocumentType>> {
        return __request({
            method: 'GET',
            path: `/v1/document-type`,
            query: {
                'business_account_id': businessAccountId,
            },
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Gets one default document type
     * @param dtid ID of the document type
     * @returns DocumentType OK
     * @throws ApiError
     */
    public static getDefaultDocumentType(
        dtid: string,
    ): CancelablePromise<DocumentType> {
        return __request({
            method: 'GET',
            path: `/v1/document-type/${dtid}`,
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Deletes a business account document type
     * @param id ID of the business account
     * @param dtid ID of the document type
     * @returns DeleteResponse OK
     * @throws ApiError
     */
    public static deleteBusinessAccountDocumentTypeV2(
        id: string,
        dtid: string,
    ): CancelablePromise<DeleteResponse> {
        return __request({
            method: 'DELETE',
            path: `/v2/business-account/${id}/document-type/${dtid}`,
            errors: {
                400: `Bad Request`,
                422: `Unprocessable Entity`,
                500: `Internal Server Error`,
            },
        });
    }

}